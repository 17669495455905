import { string } from 'prop-types';

const JobPositionCard = ({ positionTitle, ...otherProps }) => (
  <a
    className="flex justify-between select-none items-center cursor-pointer p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3"
    {...otherProps}
  >
    <div className="font-medium">{positionTitle}</div>
    <svg
      className="w-4 h-4 fill-current text-black flex-shrink-0 ml-6"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
    </svg>
  </a>
);

JobPositionCard.propTypes = {
  positionTitle: string.isRequired
};

export default JobPositionCard;
