import { useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import { ReactComponent as IconArrowRight } from '../assets/arrow-right-icon.svg';
import { signInWithGoogle } from '../firebase/firebase.utils';
import { AuthContext } from '../context/AuthContext';
import PageLoader from '../components/PageLoader';
import SupportSection from '../components/SupportSection';
import { ReactComponent as AddonExtensionCTA } from '../assets/addon-extension-badge.svg';

const GMAIL_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/record-voice-messages-in/kcgmbngghfpnddnfccnabnioiidenipc';
const DOCS_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/record-voice-comments-in/lbfofpndjldbnhnhibhmggblcbpoomon';

const Login = () => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);
  const history = useHistory();
  const { state } = history.location;

  const loginFromRecordPageWithService = state && state.startsWith('record/');

  const redirectURL =
    state === 'record/docs' ? DOCS_EXTENSION_URL : GMAIL_EXTENSION_URL;

  if (currentUser) {
    if (history.location.state) {
      return <Redirect to={`/${history.location.state}`} />;
    }
    return <Redirect to="/welcome" />;
  }

  if (loadingAuthState) {
    return <PageLoader />;
  }

  return (
    <Layout hideFooter hideHeaderButtons>
      <PageHeading
        title="Log In"
        description={['Welcome back! Continue with Google to log in.']}
      />
      <div className="w-full max-w-md mx-auto mt-14 mb-6">
        <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
          Continue with Google
          <IconArrowRight className="w-3 h-3 ml-8 mr-2 sm:mb-0.5 fill-current text-white items-end" />
        </CustomButton>
      </div>
      {loginFromRecordPageWithService && (
        <div className="flex items-center justify-center mb-14">
          <a href={redirectURL} target="_blank" rel="noreferrer">
            <AddonExtensionCTA className="w-full h-auto cursor-pointer" />
          </a>
        </div>
      )}
      <p className="mb-6 text-lg">
        Don’t have an account?
        <Link
          to="/create-account"
          className="text-blue-500 mx-1 block sm:inline-block"
        >
          Create an Account
        </Link>
      </p>
      <p className="text-xs">
        By continuing, you agree to our
        <Link to="/terms" className="text-blue-500 mx-1">
          terms of service
        </Link>
        and
        <Link to="/privacy" className="text-blue-500 mx-1">
          privacy policy
        </Link>
        , and you opt in to receive emails from us.
      </p>
      {loginFromRecordPageWithService && <SupportSection />}
    </Layout>
  );
};

export default Login;
