import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { func, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Modal from './Modal';
import PageHeading from './PageHeading';
import MiniPlayer from './MiniPlayer';
import retrieveBeep, { deleteAudio, handleExpirationDateChange } from '../utils/api';
import { AuthContext } from '../context/AuthContext';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';
import { ReactComponent as MicrophoneIcon } from '../assets/microphone-icon.svg';
import { getCount, setCount } from '../utils/utils';
import { BEEP_RECORD_COUNT } from '../utils/constants';
import { customStyles, selectOptions } from '../utils/select';
import FileLoader from './FileLoader';

const SuccessfulRecord = ({
  audioURL,
  startRecording,
  textContent
}) => {
  const [isModalOpen, toggleModalOpen] = useState(false);
  const [isExtensionModalOpen, toggleGetExtensionModal] = useState(false);
  const [isInfoModalOpen, toggleInfoModalOpen] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useState(false);
  const { tokenId } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState({ value: 'never', label: 'Never' });
  const { audio, fetchingAudio } = retrieveBeep(audioURL.filename, tokenId, {
    revalidateOnFocus: false
  });

  const urlInput = useRef();
  const history = useHistory();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const copyToClipboard = useCallback(() => {
    function listener(e) {
      e.preventDefault();
      e.clipboardData.setData('text/html', `<a href=${audioURL.url}>${audioURL.url}</a>`);
      e.clipboardData.setData('text/plain', audioURL.url);
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }, [audioURL.url]);

  const iosCopyToClipboard = useCallback(() => {
    const element = document.createElement('input');
    element.setAttribute('style', 'position:absolute;top:-9999px');
    element.value = audioURL.url;
    document.body.appendChild(element);
    const range = document.createRange();
    element.contentEditable = true;
    element.readOnly = false;
    range.selectNodeContents(element);
    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);
    element.setSelectionRange(0, 999999);
    function listener(e) {
      e.preventDefault();
      e.clipboardData.setData('text/html', `<a href=${audioURL.url}>${audioURL.url}</a>`);
      e.clipboardData.setData('text/plain', audioURL.url);
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    element.remove();
  }, [audioURL.url]);

  useEffect(() => {
    urlInput.current.focus();

    isSafari ? iosCopyToClipboard() : copyToClipboard();

    const currentRecordCount = getCount(BEEP_RECORD_COUNT) || 0;
    setCount(BEEP_RECORD_COUNT, currentRecordCount + 1);
    const updatedPlayCount = currentRecordCount + 1;

    if (updatedPlayCount === 5 || updatedPlayCount === 15 || updatedPlayCount % 30 === 0) {
      toggleGetExtensionModal(true);
    }
  }, [audioURL.url, isSafari, iosCopyToClipboard, copyToClipboard]);

  const selectAllText = () => {
    urlInput.current.select();
  };

  const askForAuthModal = () => {
    if (!tokenId) {
      toggleInfoModalOpen(true);
    }
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    handleExpirationDateChange(option.value, audioURL.filename, tokenId);
  };

  const handleCopyLink = () => {
    isSafari ? iosCopyToClipboard() : copyToClipboard();
    toggleModalOpen(true);
  };

  const handleDelete = async () => {
    const { deleted } = await deleteAudio(audioURL.filename, tokenId);
    if (deleted) return history.go(0);

    return alert(
      'An error occurred while trying to delete the audio. Please try again.'
    );
  };

  const handleDownload = async () => {
    console.log('Download audio file');
  };

  const handleExtensionModal = () => {
    window.open('https://www.beepaudio.com/welcome');
    toggleGetExtensionModal(false);
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          closeModal={(state) => toggleModalOpen(!state)}
          textContent={textContent.modal}
        />
      )}
      {isExtensionModalOpen && (
        <Modal
          closeModal={(state) => toggleGetExtensionModal(!state)}
          type="extension_redirect"
          textContent={textContent.extension_modal}
          handleSubmit={handleExtensionModal}
          submitButtonText="Get It Now"
          closeBtnText="No Thanks"
          alignVertically
        />
      )}
      {isInfoModalOpen && (
        <Modal
          closeModal={(state) => toggleInfoModalOpen(!state)}
          type="info"
          textContent={{
            heading: 'You are not logged in',
            description:
              'Only logged users are able to record and delete beeps. Please log in.'
          }}
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          textContent={{
            heading: 'Delete Recording',
            description:
              'Are you sure you want to permanently delete this recording?'
          }}
          type="warning"
          closeModal={(modalState) => toggleDeleteModalOpen(!modalState)}
          handleSubmit={() => handleDelete()}
          submitButtonText="Delete"
        />
      )}
      <PageHeading
        title="Your Recording is Ready"
        description={[textContent.description]}
      />
      {fetchingAudio && <FileLoader />}
      {audio && <MiniPlayer {...audio} />}
      <input
        ref={urlInput}
        value={audioURL.url}
        type="text"
        className="truncate p-3 w-full sm:max-w-sm text-center select-all text-sm sm:text-base focus:outline-none tracking-tighter border-solid border-2 border-gray-200 rounded-md inline-block"
        onClick={selectAllText}
        readOnly
      />
      <div className="flex items-center justify-center max-w-sm mx-auto my-4">
        <p className="mr-2 text-md text-gray-900 font-bold">Auto-Delete/Expire</p>
        <Select
          value={selectedOption}
          onChange={handleSelectChange}
          options={selectOptions}
          styles={customStyles()}
          isSearchable={false}
          isOptionDisabled={(option) => option.disabled}
        />
        <span className="mx-4 hidden">|</span>
        <button
          type="button"
          className="text-left hover:text-gray-600 focus:outline-none hidden"
          onClick={handleDownload}
        >
          <DownloadIcon className="inline-block mr-1 mb-1 w-4 h-auto" />
          Download
        </button>
      </div>
      <div>
        <button
          type="button"
          onClick={handleCopyLink}
          className="bg-gray-900 text-white hover:bg-red-600 rounded-xl w-full sm:max-w-sm shadow-around mt-6 py-4 px-2 focus:outline-none"
        >
          {textContent.button.icon && <div>ICONE</div>}
          {textContent.button.text}
        </button>
      </div>
      <div className="flex justify-center max-w-sm mx-auto my-4">
        <button
          type="button"
          className="ml-6 text-right hover:text-gray-600 focus:outline-none"
          onClick={() => {
            tokenId ? toggleDeleteModalOpen(true) : askForAuthModal();
          }}
        >
          <DeleteIcon className="inline-block mr-1 mb-1 w-4 h-auto" />
          Delete
        </button>
        <span className="mx-4">|</span>
        <button
          type="button"
          className="text-left hover:text-gray-600 focus:outline-none"
          onClick={() => {
            tokenId ? startRecording() : askForAuthModal();
          }}
        >
          <MicrophoneIcon className="inline-block mr-1 mb-1 w-4 h-auto" />
          Record Again
        </button>
      </div>
    </>
  );
};

SuccessfulRecord.propTypes = {
  audioURL: shape({
    url: string,
    filename: string
  }).isRequired,
  startRecording: func.isRequired,
  textContent: shape({
    description: string.isRequired,
    button: shape({
      text: string,
      icon: string
    }).isRequired,
    extension_modal: shape({
      heading: string,
      description: string
    }).isRequired,
    modal: shape({
      heading: string,
      description: string
    }).isRequired
  }).isRequired
};

export default React.memo(SuccessfulRecord);
