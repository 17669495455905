import { Link, Redirect, useParams } from 'react-router-dom';
import CallToAction from '../components/CallToAction';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import { PRODUCT_DATA } from '../utils/constants';
import Carousel from '../components/Carousel';

const Products = () => {
  const { product } = useParams();

  const productData = PRODUCT_DATA[product];

  if (!productData) return <Redirect to="/404" />;

  const { title, description, image, callToAction } = productData;

  return (
    <Layout
      title={productData.meta.title}
      description={productData.meta.description}
    >
      <section>
        <PageHeading title={title} description={description} />
        <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center pb-12 md:pb-16">
          <Link
            className="btn text-white bg-black hover:bg-blue-700 w-full sm:w-auto mb-8 md:mb-10"
            to="/create-account"
          >
            Get It Now
          </Link>
        </div>
        <div className="flex flex-col justify-center text-center">
          <Link to="/create-account">
            <img
              src={image}
              className="mx-auto"
              width="768"
              height="432"
              alt="Voice Comments on Docs and Recording Widget"
              aria-hidden="true"
            />
          </Link>
          <h4 className="my-8 text-sm text-gray-600">
            *We are not affiliated with or endorsed by Google in any way
          </h4>
        </div>
      </section>
      <Carousel />
      <CallToAction {...callToAction} />
    </Layout>
  );
};

export default Products;
