import { bool, func } from 'prop-types';
import { useEffect, useState } from 'react';
import PageHeading from './PageHeading';

const SPACE_KEY = 32;
const S_KEY = 83;
// const ESCAPE_KEY = 27;
// const D_KEY = 68;

const RecordingWidget = ({ isProcessing, stopRecording, deleteRecording }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const startTime = performance.now();
    let timerUpdate;

    const updateTimer = (timer) => {
      const elapsed = Math.round((timer - startTime) / 1000);

      if (elapsed >= 300) {
        cancelAnimationFrame(timerUpdate);
        stopRecording();
      }

      setElapsedTime(elapsed);
      timerUpdate = requestAnimationFrame(updateTimer);
    };

    timerUpdate = requestAnimationFrame(updateTimer);
    return () => cancelAnimationFrame(timerUpdate);
  }, [stopRecording]);

  useEffect(() => {
    const handleUserKeyPress = (event) => {
      event.preventDefault();
      const { keyCode } = event;

      // TODO: Investigate why deleteRecording triggers multiple times
      // if (keyCode === D_KEY || keyCode === ESCAPE_KEY) {
      //   deleteRecording();
      // }

      if (keyCode === S_KEY || keyCode === SPACE_KEY) {
        stopRecording();
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [deleteRecording, stopRecording]);

  const formatTimer = () => {
    const elapsedMinutes = Math.floor(elapsedTime / 60);
    const elapsedSeconds = elapsedTime - elapsedMinutes * 60;

    return `${elapsedMinutes}:${elapsedSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <PageHeading
        title="Record a Beep"
        description={['Recording in progress.']}
      />
      <div className="rounded-xl w-11/12 sm:w-96 shadow-around mb-16 mx-auto sm:mb-2 py-6 sm:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          {isProcessing ? (
            <div className="flex items-center justify-between my-0 mx-auto">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="animate-spin"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#e15b64"
                  strokeWidth="10"
                  r="35"
                  strokeDasharray="164.93361431346415 56.97787143782138"
                />
              </svg>
              <span className="text-xl text-gray-800 ml-4">Processing...</span>
            </div>
          ) : (
            <>
              <div className="px-4 flex-col items-center">
                <span className="text-gray-900 text-4xl tracking-tight font-black select-none">
                  {formatTimer()}
                </span>
                <div className="flex items-center tracking-tighter font-bold py-1 text-xs px-2 border-2 select-none border-gray-200 rounded-md">
                  <span className="block rounded-full w-3 h-3 mr-2 animate-pulse bg-red-500" />
                  Recording
                </div>
              </div>
              <div className="mt-4 sm:mt-0">
                <button
                  onClick={() => deleteRecording()}
                  className="border-4 h-20 w-20 rounded-md tracking-tighter text-center cursor-pointer focus:outline-none hover:bg-gray-200"
                  type="button"
                  title="Delete Audio Record"
                  aria-label="Delete Audio Record"
                >
                  Delete
                </button>
                <button
                  onClick={() => stopRecording()}
                  className="ml-4 w-20 h-20 border-4 tracking-tighter rounded-full border-gray-900 text-gray-900 focus:outline-none text-center cursor-pointer hover:bg-gray-900 hover:text-gray-50"
                  type="button"
                  title="Save Audio Record"
                  aria-label="Save Audio Record"
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

RecordingWidget.propTypes = {
  stopRecording: func.isRequired,
  deleteRecording: func.isRequired,
  isProcessing: bool.isRequired
};

export default RecordingWidget;
