const SupportSection = () => (
  <div className="fixed gilroy leading-tight text-sm bottom-0 left-0 w-full p-3 bt border-gray-300 border-t bg-gray-100">
    Need help or have feature requests?
    <span className="block">
      Contact us
      <a
        className="ml-1 text-blue-500"
        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Web+Support+Issue&to=support@beepaudio.com"
        target="_blank"
        rel="nofollow noreferrer"
      >
        support@beepaudio.com
      </a>
    </span>
  </div>
);

export default SupportSection;
