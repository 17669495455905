import { Link } from 'react-router-dom';
import BeepDocsImage from '../assets/beep-google-docs.png';

const PromotionalBanner = () => (
  <section className="flex flex-col md:flex-row max-w-5xl mx-auto items-center text-center md:text-left py-12 mt-12 md:mt-20 border-t border-gray-200">
    <div className="md:pr-20 order-2 md:order-1">
      <h2 className="text-4xl leading-none lg:leading-tighter tracking-tighter mt-12 mb-4">
        Play recordings without having to open this page
      </h2>
      <p className="mx-auto my-8 text-xl text-gray-600 mb-8">
        Save time....Create & listen to embedded audio messages without leaving
        Google Classroom, Gmail, or Google Docs.
      </p>
      <Link
        className="btn text-white bg-black hover:bg-blue-700 w-full sm:w-auto mb-8 md:mb-10"
        to="/create-account"
      >
        Get It Now
      </Link>
    </div>
    <div className="mx-auto order-1 md:order-2">
      <img
        src={BeepDocsImage}
        width="768"
        height="432"
        alt="Voice Comments on Docs and Recording Widget"
        aria-hidden="true"
      />
    </div>
  </section>
);

export default PromotionalBanner;
