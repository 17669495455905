/* eslint-disable consistent-return */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { postAudio } from './api';
// import finalBeep from '../assets/finalBeep.m4a';

const TOTAL_RETRY = 3;

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState({ url: null, filename: null });
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [proceedSavingData, setProceedSavingData] = useState(true);
  const [recorder, setRecorder] = useState(null);
  const [hasError, setError] = useState(false);
  const [recordingSource, setRecordingSource] = useState(null);

  const { tokenId } = useContext(AuthContext);

  const requestRecorder = () =>
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        setIsRecording(true);
        setAudioURL({ url: null, filename: null });
        setProceedSavingData(true);

        let mediaRecorder;
        if (MediaRecorder.isTypeSupported('audio/webm')) {
          mediaRecorder = new MediaRecorder(stream);
        } else {
          const workerOptions = {
            OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
            WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
          };
          // eslint-disable-next-line no-undef
          window.MediaRecorder = OpusMediaRecorder;
          mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }, workerOptions);
        }

        setRecorder(mediaRecorder);
      })
      .catch(() => {
        try {
          navigator.permissions
            .query({ name: 'microphone' })
            .then((permission) => {
              if (permission.state !== 'granted') {
                alert('Your microphone is blocked. Enable it to record your Beep.');
              }
              setProceedSavingData(true);
              setAudioURL({ url: null, filename: null });
              setIsRecording(false);
            });
        } catch (error) {
          alert('Your browser is not supported, please update your browser or try a different browser like Chrome or Firefox');
          setProceedSavingData(true);
          setAudioURL({ url: null, filename: null });
          setIsRecording(false);
        }
      });

  useEffect(() => {
    let chunks = [];

    if (recorder === null) {
      return;
    }

    try {
      if (isRecording) {
        chunks = [];
        recorder.start();
      } else {
        recorder.stop();
      }
    } catch (error) {
      setError(true);
    }

    const stopStream = (stream) => {
      stream.getTracks().forEach((track) => track.stop());
    };

    const handleData = (e) => {
      chunks.push(e.data);
    };

    const handleOnStop = async () => {
      setIsProcessing(true);

      // await playBeep(finalBeep);

      stopStream(recorder.stream);

      if (recorder.state === 'inactive' && proceedSavingData) {
        const blob = new Blob(chunks, { type: 'audio/webm' });

        const formData = new FormData();
        formData.append('file', blob);

        try {
          const { url, filename } = await postAudio(formData, tokenId, TOTAL_RETRY, recordingSource);
          setAudioURL({ url, filename });
        } catch (err) {
          setError(true);
        }
      }
      setRecorder(null);
      setIsProcessing(false);
    };

    recorder.addEventListener('dataavailable', handleData);
    recorder.addEventListener('stop', handleOnStop);
    return () => {
      recorder.removeEventListener('dataavailable', handleData);
      recorder.removeEventListener('stop', handleOnStop);
    };
  }, [recorder, isRecording, tokenId, proceedSavingData, recordingSource]);

  const startRecording = async () => {
    await requestRecorder();
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const deleteRecording = () => {
    setProceedSavingData(false);
    setIsRecording(false);
  };

  return {
    isRecording,
    hasError,
    isProcessing,
    audioURL,
    startRecording,
    stopRecording,
    setRecordingSource,
    deleteRecording
  };
};

export default useRecorder;
