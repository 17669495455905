/* eslint-disable no-shadow */
import { func, string } from 'prop-types';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import {
  getPlaybackSpeedLocally,
  setPlaybackSpeedLocally
} from '../utils/utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const playbackRates = ['0.8', '1.0', '1.2', '1.5', '2.0'];

const PlaybackSelector = ({ setPlayback, extendClass }) => {
  const localPlayback = getPlaybackSpeedLocally() || '1.0';
  const [selected, setSelected] = useState(localPlayback);

  const handlePlaybackChange = (playbackRate) => {
    setPlaybackSpeedLocally(playbackRate);
    setPlayback(Number(playbackRate));
    setSelected(playbackRate);
  };

  return (
    <Listbox value={selected} onChange={handlePlaybackChange}>
      {({ open }) => (
        <>
          <div className="mt-1 absolute left-0">
            <Listbox.Button
              className={`relative w-full select-none rounded-md p-2 text-left cursor-pointer text-xs pl-0.5 focus:outline-none ${extendClass}`}
              title="Tap to change playback speed"
            >
              {`${selected}x`}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                <Listbox.Option
                  className="text-gray-900 text-xs leading-3 text-left p-2 select-none font-bold"
                  disabled
                >
                  Playback Speed
                </Listbox.Option>
                {playbackRates.map((rate, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active && 'bg-gray-200',
                        'cursor-pointer select-none text-gray-900 relative p-2 pr-8'
                      )}
                    value={rate}
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}
                          >
                            {`${rate}x`}
                          </span>
                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 text-indigo-600 right-0 flex items-center pr-2'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

PlaybackSelector.propTypes = {
  setPlayback: func.isRequired,
  extendClass: string
};

PlaybackSelector.defaultProps = {
  extendClass: null
};

export default PlaybackSelector;
