export const customStyles = (customOption = null, customControl = null) => ({
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    fontSize: '.8rem',
    color: state.isDisabled ? 'gray' : provided.color,
    ...customOption
  }),
  control: (provided) => ({
    ...provided,
    fontSize: '.8rem',
    width: 'max-content',
    minWidth: '150px',
    textAlign: 'left',
    height: 28,
    minHeight: 28,
    ...customControl
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px'
  })
});

export const selectOptions = [
  { value: '', label: 'AUTOMATICALLY DELETE...', disabled: true },
  { value: 'never', label: 'Never' },
  { value: '1_day', label: 'In 1 Day' },
  { value: '1_week', label: 'In 1 Week' },
  { value: '6_months', label: 'In 6 Months' },
  { value: '1_year', label: 'In 1 Year' }
];
