import useSWR from 'swr';
import { delay } from './utils';

export const getAudio = async (url, tokenId) => {
  const headers = new Headers();

  if (tokenId) {
    headers.append('Authorization', `Bearer ${tokenId}`);
    headers.append('X-AUDIO-RECORDING-SOURCE', 'BEEP_WEB');
  }
  const res = await fetch(url, {
    headers
  });

  if (res.status !== 200) {
    const error = new Error('Something went wrong. Please try again.');
    throw error;
  }

  return res.json();
};

export const postAudio = async (
  audioFile,
  tokenId = undefined,
  retry = 0,
  source = 'BEEP_WEB'
) => {
  const res = await fetch(
    'https://api.beepaudio.com/recordings/?expires_in=never',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenId}`,
        'X-AUDIO-RECORDING-SOURCE': source
      },
      body: audioFile
    }
  ).catch(() => ({
    filename: null,
    url: null
  }));

  if (res.status === 201) {
    return res.json();
  }

  if (retry > 0) {
    await delay(2000);
    return postAudio(audioFile, tokenId, retry - 1, source);
  }

  const error = new Error('Something went wrong. Please try again.');
  throw error;
};

export const deleteAudio = async (audioFile, tokenId = undefined) => {
  const res = await fetch(`https://api.beepaudio.com/recordings/${audioFile}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${tokenId}`,
      'X-AUDIO-RECORDING-SOURCE': 'BEEP_WEB'
    }
  }).catch(() => ({
    deleted: false
  }));

  if (res.status === 204) {
    return { deleted: true };
  }

  return res;
};

const retrieveBeep = (audioUrl, tokenId = undefined) => {
  const response = useSWR(
    [`https://api.beepaudio.com/recordings/${audioUrl}`, tokenId],
    getAudio
  );

  if (response.error) {
    return {
      audio: undefined,
      fetchingAudio: false,
      error: response.error
    };
  }

  if (!response.data) {
    return {
      audio: undefined,
      fetchingAudio: true,
      error: undefined
    };
  }

  return {
    audio: response.data,
    fetchingAudio: false,
    error: undefined
  };
};

export const handleExpirationDateChange = async (
  expiration,
  fileName,
  tokenId = undefined
) => {
  const res = await fetch(`https://api.beepaudio.com/recordings/${fileName}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${tokenId}`
    },
    body: JSON.stringify({
      expires_at: expiration
    })
  }).catch(() => ({
    expires_at: null
  }));

  if (res.status === 200) {
    return res.json();
  }

  return res;
};

export default retrieveBeep;

export const deleteAccount = async (tokenId = null) => {
  const res = await fetch('https://api.beepaudio.com/profile/me', {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${tokenId}`
    }
  }).catch(() => ({
    error: true
  }));

  if (res.status === 200) {
    return res.json();
  }

  return res;
};
