/* eslint-disable no-return-assign */
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { ReactComponent as IconArrowRight } from '../assets/arrow-right-icon.svg';

const NewsletterForm = () => (
  <MailchimpSubscribe
    url={process.env.REACT_APP_MAILCHIMP_URL}
    render={({ subscribe, status }) => {
      let email;

      const verifyEmail = () => {
        if (email && email.value.indexOf('@') > -1) {
          subscribe({ EMAIL: email.value });
        }
      };

      return (
        <div className="flex flex-wrap mb-4">
          <div className="w-full">
            <div className="relative flex items-center max-w-xs">
              <input
                className="form-input border-solid border-2 rounded-md border-light-blue-500 focus:border-blue-300 outline-none w-full text-gray-800 px-3 py-2 pr-12 text-sm leading-tight"
                ref={(node) => (email = node)}
                type="email"
                placeholder="Your email"
                required
              />
              <button
                type="button"
                className="focus:outline-none cursor-pointer"
                aria-label="Subscribe"
                onClick={verifyEmail}
              >
                <span
                  className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300"
                  aria-hidden="true"
                />
                <IconArrowRight className="w-3 h-3 fill-current text-black mx-3 flex-shrink-0" />
              </button>
            </div>
            {status === 'sending' && (
              <p className="mt-2 text-blue-600 ml-4 text-sm leading-tight">
                sending...
              </p>
            )}
            {status === 'error' && (
              <p className="mt-2 text-blue-600 ml-4 text-sm leading-tight">
                An error occurred. Try again later.
              </p>
            )}
            {status === 'success' && (
              <p className="mt-2 text-green-600 ml-4 text-sm leading-tight">
                Thanks for subscribing!
              </p>
            )}
          </div>
        </div>
      );
    }}
  />
);
export default NewsletterForm;
