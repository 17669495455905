import { node } from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase/firebase.utils';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [currentUser, setUser] = useState(undefined);
  const [tokenId, setUserToken] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        await user.getIdToken(true).then((token) => {
          setUserToken(token);
        });
      } else {
        setUserToken(null);
        setUser(null);
      }
      setLoadingAuthState(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, tokenId, loadingAuthState, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: node.isRequired
};

export default AuthProvider;
