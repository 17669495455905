import TeamImage01 from '../assets/join-us-01.webp';
import TeamImage02 from '../assets/join-us-02.webp';
import TeamImage03 from '../assets/join-us-03.webp';
import TeamImage04 from '../assets/join-us-04.webp';

const ImageMosaic = () => (
  <div className="max-w-3xl mx-auto">
    <div className="pb-12 md:pb-20">
      <div className="relative w-full h-0 pb-3/4">
        <figure
          className="absolute h-auto"
          style={{ bottom: '9%', width: '41.7%', maxWidth: '320px' }}
          data-aos="fade-right"
        >
          <img
            className="shadow-2xl"
            src={TeamImage03}
            width="320"
            height="240"
            alt="Teammates working"
            aria-hidden="true"
          />
        </figure>
        <figure
          className="absolute"
          style={{ left: '14.8%', top: '11.8%', width: '70.3%', maxWidth: '540px' }}
          data-aos="fade-down"
          data-aos-delay="150"
        >
          <img
            className="shadow-2xl"
            src={TeamImage01}
            width="540"
            height="360"
            alt="Teammates working"
            aria-hidden="true"
          />
        </figure>
        <figure
          className="absolute h-auto"
          style={{ bottom: '15%', right: '6.2%', width: '41.7%', maxWidth: '320px' }}
          data-aos="fade-left"
          data-aos-delay="300"
        >
          <img
            className="shadow-2xl"
            src={TeamImage04}
            width="320"
            height="240"
            alt="Teammates working"
            aria-hidden="true"
          />
        </figure>
        <figure
          className="absolute h-auto"
          style={{ top: '5.9%', right: '0', width: '23.4%', maxWidth: '180px' }}
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          <img
            className="shadow-2xl"
            src={TeamImage02}
            width="180"
            height="180"
            alt="Teammates working"
            aria-hidden="true"
          />
        </figure>
      </div>
    </div>
  </div>
);

export default ImageMosaic;
