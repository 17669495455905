import { bool, func, node, shape, string } from 'prop-types';

const MODAL_STYLE = {
  warning: {
    icon: String.fromCharCode('0x026a0'),
    baseColor: 'text-red-700 bg-red-100'
  },
  info: {
    icon: String.fromCharCode('0x0203c'),
    baseColor: 'text-blue-700 bg-blue-100'
  },
  success: {
    icon: String.fromCharCode('0x2714'),
    baseColor: 'text-green-700 bg-green-100'
  }
};

const Modal = ({
  handleSubmit,
  closeModal,
  type,
  textContent,
  submitButtonText,
  closeBtnText,
  alignVertically,
  hideButtons
}) => (
  <div
    className="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />
      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl h-1/2  transform transition-all sm:my-8 align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div
            className={`sm:flex sm:items-start ${
              alignVertically ? 'flex flex-col center' : ''
            }`}
          >
            {textContent.image ? (
              <img
                src={textContent.image}
                alt="Beep extension in action"
                className="w-4/5 self-center"
              />
            ) : (
              <div
                className={`mx-auto flex-shrink-0 flex select-none items-center justify-center h-12 w-12 rounded-full ${
                  MODAL_STYLE[type].baseColor
                } ${alignVertically ? '' : 'sm:mx-0 sm:h-10 sm:w-10'}`}
              >
                {MODAL_STYLE[type].icon}
              </div>
            )}
            <div
              className={`mt-3 text-center ${
                alignVertically ? '' : 'sm:mt-0 sm:ml-4 sm:text-left'
              }`}
            >
              <h3
                className="text-2xl tracking-tighter leading-tighter font-medium text-gray-900"
                id="modal-title"
              >
                {textContent.heading}
              </h3>
              <div className="mt-2">
                <p className="text-lg tracking-tight leading-tight text-gray-700">
                  {textContent.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex bg-gray-50 px-4 py-3 sm:px-6 sm:justify-end ${
            alignVertically ? 'flex-col-reverse' : ''
          }`}
        >
          {!hideButtons && (
            <>
              <button
                onClick={closeModal}
                type="button"
                className={`mt-3 w-full inline-flex justify-center rounded-md border-gray-700 text-gray-700 px-4 py-2 text-base font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  alignVertically
                    ? ''
                    : 'sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm shadow-sm border-2'
                } `}
              >
                {closeBtnText}
              </button>
              {handleSubmit && (
                <button
                  onClick={handleSubmit}
                  type="button"
                  className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    alignVertically
                      ? ''
                      : 'sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  } `}
                >
                  {submitButtonText}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  handleSubmit: func,
  submitButtonText: string,
  closeModal: func.isRequired,
  closeBtnText: string,
  alignVertically: bool,
  textContent: shape({
    heading: string,
    description: string,
    image: node
  }).isRequired,
  type: string,
  hideButtons: bool
};

Modal.defaultProps = {
  type: 'success',
  handleSubmit: null,
  submitButtonText: 'Submit',
  closeBtnText: 'Close',
  hideButtons: false,
  alignVertically: false
};

export default Modal;
