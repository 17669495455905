import ServiceDocs from '../assets/beep-google-docs.png';
import ServiceGmail from '../assets/beep-gmail.png';
import ServiceClassroom from '../assets/beep-google-classroom.png';
import DocsTutorial from '../assets/docs-tutorial.gif';
import GmailTutorial from '../assets/gmail-tutorial.gif';

export const PLAYBACK_LOCAL_STORAGE_KEY = 'beep_playback';
export const BEEP_RECORD_COUNT = 'beep_record_count';
export const BEEP_PLAY_COUNT = 'beep_play_count';

export const PRODUCT_DATA = {
  'google-classroom-voice': {
    title: 'Voice Feedback for Google Classroom™',
    description: [
      'Save time giving feedback and engage with students in a whole new way.  Works on Google Classroom, Google Docs, and Gmail.'
    ],
    image: ServiceClassroom,
    callToAction: {
      title: 'Want to see if it lives up to the hype?',
      description: 'Engage with your students in a whole new way'
    },
    meta: {
      title: 'Voice Notes & Feedback for Google Classroom - Beep',
      description:
        'The must have tool for teachers and professors. Cut your time in half creating assignments and giving students feedback through voice notes. Record audio feedback on Google Classroom™, Google Docs™, and Gmail™. Save time and provide friendlier, more helpful feedback.'
    }
  },
  'gmail-voice': {
    title: 'Voicemail for Gmail™',
    description: [
      'Writing the perfect response and striking the perfect tone is difficult and time consuming. With voice messages, it’s easy.'
    ],
    image: ServiceGmail,
    callToAction: {
      title: 'Overwelmed responding to long emails?',
      description: 'Skip the typing and respond with your voice'
    },
    meta: {
      title: 'Voice Notes for Gmail - Beep',
      description:
        "Record, send, and listen to audio messages and voice notes all within Gmail™. It's a faster, friendlier way to email. Perfect for project managers, seniors, CEOs, product managers, and educators. It's the next generation of voicemail for Gmail™."
    }
  },
  'google-docs-voice': {
    title: 'Give Faster & Friendlier Feedback on Google Docs™',
    description: [
      'Save time and avoid misunderstandings with voice comments for Google Docs, Slides, Forms, and Sheets.'
    ],
    image: ServiceDocs,
    callToAction: {
      title: 'Want to see if it lives up to the hype?',
      description:
        'Give feedback on Docs, Slides, Sheets, and Forms with your voice.'
    },
    meta: {
      title: 'Voice Notes for Google Docs, Forms, Sheets, & Slides - Beep',
      description:
        "Record and listen to voice comments and audio feedback on Google Docs™, Sheets, Slides, and Forms. It's great for teachers, professors, students, editors, and teams."
    }
  }
};

export const RECORD_PAGE_CONTENT = {
  gmail: {
    recordPage: {
      heading: 'Record a Voice Note for Gmail™'
    },
    successPage: {
      description: 'Click the button to copy your recording.',
      button: {
        text: 'Insert into Gmail™',
        icon: ''
      },
      extension_modal: {
        heading: 'Tired of opening up this add-on & copy/pasting every time?',
        description:
          'Get our Chrome Extension to create recordings without leaving Gmail™. No more copy/pasting. You won’t regret it.',
        image: GmailTutorial
      },
      modal: {
        heading: 'Link copied to your clipboard',
        description:
          'Return to Gmail™ & press Control + V (Mac: ⌘ Command + V) to paste the player into your message.'
      }
    },
    source: 'BEEP_ADDON_GMAIL'
  },
  docs: {
    recordPage: {
      heading: 'Record a Voice Note for Docs'
    },
    successPage: {
      description: 'Copy the link and paste it anywhere',
      button: {
        text: 'Insert into Docs',
        icon: ''
      },
      extension_modal: {
        heading: 'Tired of opening up this add-on & copy/pasting every time?',
        description:
          'Get our Chrome Extension to create AND play recordings without leaving Google Docs™ or Google Classroom™. You won’t regret it.',
        image: DocsTutorial
      },
      modal: {
        heading: 'Link copied to your clipboard',
        description:
          'Press Control + V (Mac: ⌘ Command + V) to paste the player into your comment.'
      }
    },
    source: 'BEEP_ADDON_DOCS'
  },
  classroom: {
    recordPage: {
      heading: 'Record a Voice Note for Classroom'
    },
    successPage: {
      description: 'Copy the link and paste it anywhere',
      button: {
        text: 'Insert into Google Classroom',
        icon: ''
      },
      extension_modal: {
        heading: 'Tired of opening up this add-on & copy/pasting every time?',
        description:
          'Get our Chrome Extension to create AND play recordings without leaving Google Docs™ or Google Classroom™. You won’t regret it.',
        image: DocsTutorial
      },
      modal: {
        heading: 'Link copied to your clipboard',
        description:
          'Press Control + V (Mac: ⌘ Command + V) to paste the player into your comment.'
      }
    },
    source: 'BEEP_ADDON_CLASSROOM'
  },
  default: {
    recordPage: {
      heading: 'Record a Beep'
    },
    successPage: {
      description: 'Click the button to copy your recording.',
      button: {
        text: 'Copy Link',
        icon: ''
      },
      extension_modal: {
        heading: 'Tired of opening up this page & copy/pasting every time?',
        description:
          'Get our Chrome Extension to create AND play recordings without leaving Google Docs™, Gmail™, & Google Classroom™. You won’t regret it.',
        image: DocsTutorial
      },
      modal: {
        heading: 'Link copied to your clipboard',
        description:
          "Press Control + V (Mac: ⌘ Command + V) to paste the player wherever you'd like."
      }
    },
    source: 'BEEP_WEB'
  }
};

export const PLAY_EXTENSION_MODAL_CONTENT = {
  heading: 'Tired of having to open up this page to play the recording every time?',
  description: 'Get our Chrome Extension to play recordings without leaving Google Docs™, Google Classroom™, & Gmail™. You won’t regret it.',
  image: DocsTutorial
};
