import React from 'react';
import CookieConsent from 'react-cookie-consent';

const ConsentBar = () => (
  <CookieConsent
    CookieConsent
    location="bottom"
    buttonText="I understand"
    cookieName="beep-tos-08/2022"
    style={{ background: '#000000', justifyContent: 'center', display: 'none' }}
    containerClasses="px-0 md:px-20 xl:px-40 font-harmonia"
    contentClasses="text-sm"
    buttonClasses="text-sm pr-12 font-harmonia"
    buttonStyle={{
      color: 'white',
      backgroundColor: '#00AB3A',
      borderRadius: '.5rem'
    }}
    expires={150}
  >
    ⚠️ We are experiencing issues with our server. We are investigating and
    fixing the issue. Beep will not work until this is fixed. Please follow us
    on Twitter - @beepaudio - for updates.
  </CookieConsent>
);

export default ConsentBar;
