import { node, string } from 'prop-types';
import PageHeading from './PageHeading';

const TermsBuilder = ({ title, date, children, extendClass }) => (
  <>
    <PageHeading title={title} className={`text-gray-900 ${extendClass}`} />
    {children}
    <p className="text-left">{date}</p>
  </>
);

TermsBuilder.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  children: node,
  extendClass: string
};

TermsBuilder.defaultProps = {
  children: null,
  extendClass: ''
};

const Text = ({ children, extendClass = '' }) => (
  <p className={extendClass}>{children}</p>
);

Text.propTypes = {
  children: node,
  extendClass: string
};

Text.defaultProps = {
  children: null,
  extendClass: ''
};

const Section = ({ children, extendClass = '' }) => (
  <section className={`max-w-3xl mx-auto my-12${extendClass}`}>
    {children}
  </section>
);

Section.propTypes = {
  children: node,
  extendClass: string
};

Section.defaultProps = {
  children: null,
  extendClass: ''
};

const H2 = ({ children, extendClass = '' }) => (
  <h2
    className={`text-3xl text-left leading-tighter tracking-tighter mb-4 ${extendClass}`}
  >
    {children}
  </h2>
);

H2.propTypes = {
  children: node,
  extendClass: string
};

H2.defaultProps = {
  children: null,
  extendClass: ''
};

const H3 = ({ children, extendClass = '' }) => (
  <h3
    className={`text-3xl text-left leading-tighter tracking-tighter mb-4 ${extendClass}`}
  >
    {children}
  </h3>
);

H3.propTypes = {
  children: node,
  extendClass: string
};

H3.defaultProps = {
  children: null,
  extendClass: ''
};

const List = ({ children, extendClass }) => (
  <ul
    className={`text-left list-disc list-inside my-8 text-gray-600 ${extendClass}`}
  >
    {children}
  </ul>
);

List.propTypes = {
  children: node,
  extendClass: string
};

List.defaultProps = {
  children: null,
  extendClass: ''
};

const ListItem = ({ children, extendClass }) => (
  <li className={`mb-1 ${extendClass}`}>{children}</li>
);

ListItem.propTypes = {
  children: node,
  extendClass: string
};

ListItem.defaultProps = {
  children: null,
  extendClass: ''
};

TermsBuilder.Section = Section;
TermsBuilder.Text = Text;
TermsBuilder.H2 = H2;
TermsBuilder.H3 = H3;
TermsBuilder.List = List;
TermsBuilder.ListItem = ListItem;

export default TermsBuilder;
