import { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { ReactComponent as ForwardIcon } from '../assets/skip-5-forward.svg';
import { ReactComponent as PlayButton } from '../assets/play-button.svg';
import { ReactComponent as PauseButton } from '../assets/pause-button.svg';
import { ReactComponent as BackwardIcon } from '../assets/skip-5-backward.svg';
import PlaybackSelector from './PlaybackSelector';
import { getCount, setCount } from '../utils/utils';
import { BEEP_PLAY_COUNT, PLAY_EXTENSION_MODAL_CONTENT } from '../utils/constants';
import Modal from './Modal';

const SPACE_KEY = 32;

const PlayerControls = ({
  backAudio,
  skipAudio,
  setPlaybackSpeed,
  isPlaying,
  setIsPlaying,
  miniPlayer
}) => {
  const [isExtensionModalOpen, toggleGetExtensionModal] = useState(false);

  useEffect(() => {
    const handleUserKeyPress = (event) => {
      event.preventDefault();
      const { keyCode } = event;

      if (keyCode === SPACE_KEY) {
        setIsPlaying((state) => !state);
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [setIsPlaying]);

  const handlePlayClick = () => {
    if (!miniPlayer) {
      const currentPlayCount = getCount(BEEP_PLAY_COUNT) || 0;
      setCount(BEEP_PLAY_COUNT, currentPlayCount + 1);
      const updatedPlayCount = currentPlayCount + 1;

      if (updatedPlayCount === 5 || updatedPlayCount === 15 || updatedPlayCount % 30 === 0) {
        return toggleGetExtensionModal(true);
      }
    }

    return setIsPlaying(true);
  };

  const handleSubmit = () => {
    window.open('https://www.beepaudio.com/welcome');
    toggleGetExtensionModal(false);
  };

  return (
    <div className="flex justify-center items-center controls relative">
      {setPlaybackSpeed && (
        <PlaybackSelector
          setPlayback={(rate) => setPlaybackSpeed(rate)}
          extendClass="-mt-3"
        />
      )}
      <BackwardIcon
        className="cursor-pointer"
        onClick={backAudio}
        title="Rewind 5 seconds"
      />
      <div
        className={`cursor-pointer ${setPlaybackSpeed ? 'mx-8 md:mx-6' : 'mx-4'}`}
      >
        {isPlaying ? (
          <PauseButton
            onClickCapture={() => setIsPlaying(false)}
            title="Pause Audio"
          />
        ) : (
          <PlayButton
            onClickCapture={handlePlayClick}
            title="Play Audio"
          />
        )}
      </div>
      <ForwardIcon
        className="cursor-pointer"
        onClick={skipAudio}
        title="Skip 5 seconds"
      />
      {isExtensionModalOpen && (
        <Modal
          closeModal={(state) => toggleGetExtensionModal(!state)}
          type="extension_redirect"
          textContent={PLAY_EXTENSION_MODAL_CONTENT}
          handleSubmit={handleSubmit}
          submitButtonText="Get It Now"
          closeBtnText="No Thanks"
          alignVertically
        />
      )}
    </div>
  );
};

PlayerControls.propTypes = {
  backAudio: func.isRequired,
  skipAudio: func.isRequired,
  isPlaying: bool.isRequired,
  setPlaybackSpeed: func,
  setIsPlaying: func.isRequired,
  miniPlayer: bool
};

PlayerControls.defaultProps = {
  setPlaybackSpeed: null,
  miniPlayer: false
};

export default PlayerControls;
