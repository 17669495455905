import { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Layout from '../components/Layout';
import { AuthContext } from '../context/AuthContext';
import PageLoader from '../components/PageLoader';
import RecordingWidget from '../components/RecordingWidget';
import RecordingIdle from '../components/RecordingIdle';
import useRecorder from '../utils/useRecorder';
import SuccessfulRecord from '../components/SuccessfulRecord';
import { isServiceUrlValid } from '../utils/utils';
import { RECORD_PAGE_CONTENT } from '../utils/constants';
import SupportSection from '../components/SupportSection';
import { ReactComponent as AddonExtensionCTA } from '../assets/addon-extension-badge.svg';

const GMAIL_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/record-voice-messages-in/kcgmbngghfpnddnfccnabnioiidenipc';
const DOCS_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/record-voice-comments-in/lbfofpndjldbnhnhibhmggblcbpoomon';
const CLASSROOM_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/audio-recordings-for-goog/ooopgniccpfabocbklfljmomacccjgej';
const DEFAULT_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/record-voice-comments-in/lbfofpndjldbnhnhibhmggblcbpoomon';

const Record = () => {
  const { service } = useParams();
  const { loadingAuthState } = useContext(AuthContext);
  const {
    isRecording,
    isProcessing,
    audioURL,
    hasError,
    setRecordingSource,
    startRecording,
    stopRecording,
    deleteRecording
  } = useRecorder();

  const [redirectURL, setRedirectURL] = useState(DEFAULT_EXTENSION_URL);
  const [ctaText, setCtaText] = useState('Tired of copying and pasting');

  useEffect(() => {
    setRecordingSource(
      service ? RECORD_PAGE_CONTENT[service]?.source || 'BEEP_WEB' : 'BEEP_WEB'
    );

    switch (service) {
      case 'docs':
        setRedirectURL(DOCS_EXTENSION_URL);
        setCtaText('Tired of copying and pasting? Use the extension to automatically insert your recordings.');
        break;
      case 'gmail':
        setRedirectURL(GMAIL_EXTENSION_URL);
        setCtaText('Tired of copying and pasting? Use the extension to automatically insert your recordings.');
        break;
      case 'classroom':
        setRedirectURL(CLASSROOM_EXTENSION_URL);
        setCtaText('Tired of copying and pasting? Use the extension to automatically insert your recordings.');
        break;
      default:
        setRedirectURL(DEFAULT_EXTENSION_URL);
        setCtaText('Tired of copying and pasting');
        break;
    }
  }, [setRecordingSource, service]);

  if (loadingAuthState) {
    return <PageLoader />;
  }

  if (hasError || !isServiceUrlValid(service)) {
    return <Redirect to="/error" />;
  }

  const updateMetatagForComponent = () =>
    Boolean(service) && (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );

  const pageContent =
    RECORD_PAGE_CONTENT[service] || RECORD_PAGE_CONTENT.default;

  const initialState = !isProcessing && !isRecording;
  const recorderState = isProcessing || isRecording;

  return (
    <Layout
      title="Record a Voice Note - Beep"
      description="Record a quick recording and generate a link for anyone to listen to."
      extendClass="mb-16 md:mb-24"
      hideFooter={Boolean(service)}
    >
      {updateMetatagForComponent()}
      {!audioURL.url ? (
        <>
          {initialState && (
            <RecordingIdle
              startRecording={startRecording}
              textContent={pageContent.recordPage}
            />
          )}
          {recorderState && (
            <RecordingWidget
              stopRecording={stopRecording}
              deleteRecording={deleteRecording}
              isProcessing={isProcessing}
            />
          )}
        </>
      ) : (
        <SuccessfulRecord
          audioURL={audioURL}
          startRecording={startRecording}
          textContent={pageContent.successPage}
        />
      )}
      {service && (
        <>
          <div className="flex flex-col items-center justify-center mb-14 mt-24">
            <p className="text-xs leading-tight">{ctaText}</p>
            <a href={redirectURL} target="_blank" rel="noreferrer">
              <AddonExtensionCTA className="w-full h-auto cursor-pointer" />
            </a>
          </div>
          <SupportSection />
        </>
      )}
    </Layout>
  );
};

export default Record;
