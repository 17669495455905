import { useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import { ReactComponent as IconArrowRight } from '../assets/arrow-right-icon.svg';
import { signInWithGoogle } from '../firebase/firebase.utils';
import PageLoader from '../components/PageLoader';
import { AuthContext } from '../context/AuthContext';

const SignUp = () => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);
  const history = useHistory();

  if (currentUser) {
    if (history.location.state) {
      return <Redirect to={`/${history.location.state}`} />;
    }
    return <Redirect to="/welcome" />;
  }

  if (loadingAuthState) {
    return <PageLoader />;
  }

  return (
    <Layout hideFooter hideHeaderButtons>
      <PageHeading title="Create an Account" description={['Welcome to Beep! Continue with Google to sign up.']} />
      <div className="w-full max-w-md mx-auto my-14">
        <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
          Continue with Google
          <IconArrowRight className="w-3 h-3 ml-8 mr-2 sm:mb-0.5 fill-current text-white items-end" />
        </CustomButton>
      </div>
      <p className="mb-6 text-lg">
        Already have an account?
        <Link to="/log-in" className="text-blue-500 mx-1 block sm:inline-block">
          Login
        </Link>
      </p>
      <p className="text-xs">
        By continuing, you agree to our
        <Link to="/terms" className="text-blue-500 mx-1">
          terms of service
        </Link>
        and
        <Link to="/privacy" className="text-blue-500 mx-1">
          privacy policy
        </Link>
        , and you opt in to receive emails from us.
      </p>
    </Layout>
  );
};

export default SignUp;
