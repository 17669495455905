import { useState, useEffect } from 'react';
import { getPlaybackSpeedLocally, playBeep } from './utils';
import initialBeep from '../assets/initialBeep.m4a';

const useAudioPlayer = () => {
  const localPlayback = Number(getPlaybackSpeedLocally()) || '1.0';

  const [elapsedTime, setElapsedTime] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const [audioPositionChanged, setAudioPositionValue] = useState(null);
  const [playbackSpeed, setPlaybackSpeed] = useState(localPlayback);

  useEffect(() => {
    const audio = document.getElementById('beep.audio');
    audio.playbackRate = playbackSpeed;

    audio.loop = false;

    const playAudio = async () => {
      if (audio.currentTime === 0) {
        await playBeep(initialBeep);
      }
      audio.play();
    };

    const setAudioData = () => {
      setElapsedTime(audio.currentTime);
    };

    const setAudioTime = () => {
      if (audio.currentTime >= audio.duration) {
        setPlaying(false);
        setElapsedTime(audio.duration);
        return;
      }

      setElapsedTime(audio.currentTime);
    };

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    isPlaying ? playAudio() : audio.pause();

    if (audioPositionChanged || audioPositionChanged === 0) {
      if (audioPositionChanged > audio.duration) {
        audio.currentTime = audio.duration;
      } else audio.currentTime = audioPositionChanged;

      setAudioPositionValue(null);
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, [
    elapsedTime,
    isPlaying,
    playbackSpeed,
    audioPositionChanged,
    setPlaybackSpeed
  ]);

  return {
    elapsedTime,
    isPlaying,
    setPlaying,
    setPlaybackSpeed,
    setAudioPositionValue
  };
};

export default useAudioPlayer;
