import { arrayOf, string } from 'prop-types';

const PageHeading = ({ title, emphasizeTitle, description, ...otherProps }) => (
  <header {...otherProps}>
    <h1 className="max-w-5xl mx-auto text-3xl sm:text-6xl leading-none tracking-tighter mb-4">
      {title}
      {emphasizeTitle && (
        <>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-500">
            {emphasizeTitle}
          </span>
        </>
      )}
    </h1>
    {description &&
      description.map((currentText, idx) => (
        <p
          key={idx}
          className="max-w-3xl mx-auto md:leading-snug leading-tight text-center text-xl sm:text-2xl text-gray-600 mb-8"
        >
          {currentText}
        </p>
      ))}
  </header>
);

PageHeading.propTypes = {
  title: string.isRequired,
  emphasizeTitle: string,
  description: arrayOf(string)
};

PageHeading.defaultProps = {
  emphasizeTitle: null,
  description: null
};

export default PageHeading;
