import { func, shape, string } from 'prop-types';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PageHeading from './PageHeading';
import BeepIcon from '../assets/beep-logo.png';
// import initialBeep from '../assets/initialBeep.m4a';
// import { playBeep } from '../utils/utils';

const RecordingIdle = ({ startRecording, textContent }) => {
  const { currentUser } = useContext(AuthContext);
  const { service } = useParams();

  const history = useHistory();

  const redirectIfUserNotLogged = async () => {
    const formattedRedirectPage = service ? `record/${service}` : 'record';
    if (!currentUser) return history.push({ pathname: '/log-in', state: formattedRedirectPage });

    // await playBeep(initialBeep);
    return startRecording();
  };

  return (
    <>
      <PageHeading
        className="mx-5 md:mx-48"
        title={textContent.heading}
        description={['Tap to Record.']}
      />
      <button
        type="button"
        onClickCapture={() => redirectIfUserNotLogged()}
        className="inline-block focus:outline-none cursor-pointer mb-16 md:mb-2"
      >
        <div className="flex justify-center items-center">
          <span className="animate-ping cursor-pointer absolute inline-flex w-14 h-14 rounded-full bg-red-400 opacity-75" />
          <img
            src={BeepIcon}
            className="mx-auto"
            width="60"
            height="60"
            alt="Beep Logo"
            aria-hidden="true"
          />
        </div>
      </button>
    </>
  );
};

RecordingIdle.propTypes = {
  startRecording: func.isRequired,
  textContent: shape({
    heading: string.isRequired
  }).isRequired
};

export default RecordingIdle;
