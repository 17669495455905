import getBlobDuration from 'get-blob-duration';
import {
  PLAYBACK_LOCAL_STORAGE_KEY,
  RECORD_PAGE_CONTENT
} from './constants';

const pad = (number) => (number < 10 ? `0${number}` : number);

const formatDate = (date) =>
  date.toLocaleString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

const formatDateTime = (date) =>
  date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatTime = (time) => {
  const seconds = Math.trunc(time);
  const durationMinutes = Math.floor(Math.floor(seconds) / 60);
  const durationSeconds = pad(Math.round(seconds) % 60);

  return `${durationMinutes}:${durationSeconds}`;
};

export const getFormattedDate = (epochTime) => {
  const date = new Date(epochTime * 1000);

  return `${formatDate(date)} · ${formatDateTime(date)}`;
};

export const playBeep = (audioFile) =>
  new Promise((resolve) => {
    const audio = new Audio(audioFile);

    audio.addEventListener('ended', () => {
      resolve();
    });

    try {
      audio.play();
    } catch (error) {
      console.info('Cannot Play Beep Initial/Final Sound.');
      resolve();
    }
  });

export const isServiceUrlValid = (service) => {
  // If service is undefined, the page is /record
  if (!service) return true;

  return Object.keys(RECORD_PAGE_CONTENT).indexOf(service) !== -1;
};

export const getAudioDurationFromBlob = async (blob) => {
  const duration = await getBlobDuration(blob);
  return duration;
};

export const getPlaybackSpeedLocally = () =>
  window.localStorage.getItem(PLAYBACK_LOCAL_STORAGE_KEY);

export const setPlaybackSpeedLocally = (rate) => {
  window.localStorage.setItem(PLAYBACK_LOCAL_STORAGE_KEY, rate);
};

export const getCount = (key) =>
  Number(localStorage.getItem(key));

export const setCount = (key, count) =>
  localStorage.setItem(key, count);
