import { string } from 'prop-types';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import { AuthContext } from '../context/AuthContext';

const VocoServiceLink = ({ title, description, extensionUrl }) => (
  <div className="flex flex-col sm:flex-row text-center sm:text-left items-center border-t border-gray-200 py-16 sm:py-8">
    <div className="flex-grow max-w-md">
      <h4 className="h4">{title}</h4>
      <p className="text-lg text-gray-600 my-4 sm:my-0">{description}</p>
    </div>
    <a
      className="btn text-white bg-black hover:bg-blue-700 w-full sm:w-auto select-none cursor-pointer"
      href={extensionUrl}
      target="_blank"
      rel="nofollow noreferrer"
    >
      Get It Now
    </a>
  </div>
);
VocoServiceLink.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  extensionUrl: string.isRequired
};

const Welcome = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Layout>
      <PageHeading
        title={`Welcome ${currentUser.displayName}!`}
        description={['Select any of the products below to get started. ']}
      />
      <section className="max-w-2xl mx-auto mb-16 sm:mb-32">
        <VocoServiceLink
          title="🧑🏻‍🏫 Beep for Google Classroom"
          description="Perfect for teachers and students. Works on Google Classroom, Google Docs, & Gmail."
          extensionUrl="https://chrome.google.com/webstore/detail/audio-recordings-for-goog/ooopgniccpfabocbklfljmomacccjgej"
        />
        <VocoServiceLink
          title="✉️ Beep for Gmail"
          description="Send and reply to emails with voice notes"
          extensionUrl="https://chrome.google.com/webstore/detail/kcgmbngghfpnddnfccnabnioiidenipc"
        />
        <VocoServiceLink
          title="📄 Beep for Google Docs"
          description="Leave & listen to voice comments on Google Docs"
          extensionUrl="https://chrome.google.com/webstore/detail/lbfofpndjldbnhnhibhmggblcbpoomon"
        />
        <div className="mt-4 md:mt-16">
          <a
            href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Web+Support+Issue+via+Welcome+Page&to=support@beepaudio.com"
            className="text-center text-blue-500"
            rel="nofollow"
          >
            Need help or have questions?
          </a>
        </div>
        <div className="mt-4 hidden">
          <Link to="/delete-account">Delete Account</Link>
        </div>
      </section>
    </Layout>
  );
};

export default Welcome;
