import React from 'react';
import { number, string } from 'prop-types';
import ProgressBar from './ProgressBar';
import useAudioPlayer from '../utils/useAudioPlayer';
import PlayerControls from './PlayerControls';
import PlaybackSelector from './PlaybackSelector';

const MiniPlayer = ({ url, duration }) => {
  const {
    elapsedTime,
    isPlaying,
    setPlaying,
    setPlaybackSpeed,
    setAudioPositionValue
  } = useAudioPlayer();

  return (
    <div className="flex flex-col sm:flex-row mx-auto mb-2 sm:mb-10 py-7 sm:px-7 sm:py-7 max-w-sm rounded-lg sm:rounded-md sm:shadow-around">
      <div className="flex flex-grow justify-center w-full sm:w-1/2 flex-col sm:flex-row-reverse items-center">
        <div className=" sm:ml-4 flex-grow w-full sm:w-1/2 ">
          <ProgressBar
            handleProgressChange={(time) => setAudioPositionValue(time)}
            duration={duration}
            elapsedTime={elapsedTime}
          />
          <audio id="beep.audio">
            <source src={url} />
            {`Your browser does not support the ${(
              <code>audio</code>
            )} element.`}
          </audio>
        </div>
        <div className="mx-auto mt-2 w-full h-full relative sm:ml-4 sm:w-0 sm:pl-6">
          <PlaybackSelector
            setPlayback={(rate) => setPlaybackSpeed(rate)}
            extendClass="mt-1.5 sm:mt-1 xs:mt-0.5 text-xs"
          />
        </div>
        <PlayerControls
          backAudio={() => setAudioPositionValue(elapsedTime - 5)}
          skipAudio={() => setAudioPositionValue(elapsedTime + 5)}
          isPlaying={isPlaying}
          setIsPlaying={(playingValue) => setPlaying(playingValue)}
          miniPlayer
        />
      </div>
    </div>
  );
};

MiniPlayer.propTypes = {
  url: string.isRequired,
  duration: number.isRequired
};

export default MiniPlayer;
