import MicrophoneImage from '../assets/microphone-image.webp';
import ImageMosaic from '../components/ImageMosaic';
import Layout from '../components/Layout';
import JobPositionCard from '../components/JobPositionCard';
import PageHeading from '../components/PageHeading';

const AboutUs = () => (
  <Layout
    title="About Us - Beep"
    description="We're building the future of voice communication and educational feedback. Voice has incredible potential to change how we interact with our devices, products, and each other. Learn more about us or apply to join our team. #edtech"
  >
    <section className="mb-32">
      <PageHeading
        title="Everyone Deserves to be Heard"
        description={[
          'Voice has incredible potential to change how we interact with our devices, products, and each other.', 'We interact with voice assistants like Alexa or Siri and send voice messages on iMessage or WhatsApp, yet many of the products we use daily don’t allow us to fully use our voices.',
          'Voice communication can be faster, friendlier, and easier. Our mission is simple: to design products that allow you work, live, and connect more effortlessly and naturally.'
        ]}
      />
      <figure className="flex justify-center items-start pt-4">
        <img
          className="rounded shadow-2xl"
          src={MicrophoneImage}
          width="768"
          height="432"
          alt="Microphone"
        />
      </figure>
    </section>

    <section className="max-w-3xl mx-auto mb-24">
      <h2 className="text-4xl font-extrabold text-left leading-tighter tracking-tighter mb-4">
        What’s Next?
      </h2>
      <p className="mx-auto text-justify my-8 text-xl text-gray-600 mb-8">
        Our initial products are just the beginning. Over the coming months
        we’ll be releasing more products that allow people to use their voices in new and
        novel ways.
      </p>
      <p className="mx-auto text-justify my-8 text-xl text-gray-600 mb-8">
        To get sneak peeks of what we’re working on and exclusive early access,
        please follow us on Twitter, Instagram, Facebook, or sign up for our
        newsletter in our footer.
      </p>
    </section>

    <section className="max-w-3xl mx-auto mt-12 md:mt-20 border-t border-gray-200">
      <h2 className="text-4xl font-extrabold leading-tighter tracking-tighter mt-12 mb-4">
        Join Us
      </h2>
      <p className="mx-auto my-8 text-xl text-gray-600 mb-8">
        If you want to be a part of changing how we interact with products and
        each other, we want to hear from you. We’re a small team of talented,
        creative, and passionate people who have a great time making products
        people love.
      </p>
      <ImageMosaic />
    </section>

    <section className="max-w-3xl mx-auto mb-24">
      <h3 className="text-2xl font-extrabold text-left leading-tighter tracking-tighter mb-4">
        Current open positions
      </h3>
      <JobPositionCard
        positionTitle="Senior Backend Engineer (GoLang)"
        rel="nofollow noreferrer"
        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Job+Inquiry&to=jobs@beepaudio.com"
      />
      <JobPositionCard
        positionTitle="Senior Front-end Engineer (ReactJS)"
        rel="nofollow noreferrer"
        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Job+Inquiry&to=jobs@beepaudio.com"
      />
      <JobPositionCard
        positionTitle="Senior Android Engineer (Kotlin)"
        rel="nofollow noreferrer"
        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Job+Inquiry&to=jobs@beepaudio.com"
      />
      <JobPositionCard
        positionTitle="Senior iOS Engineer (Swift)"
        rel="nofollow noreferrer"
        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Job+Inquiry&to=jobs@beepaudio.com"
      />
    </section>
  </Layout>
);

export default AboutUs;
