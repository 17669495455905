import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Layout from '../components/Layout';

const Error = ({ heading, description }) => {
  const history = useHistory();

  return (
    <Layout>
      <h1 className="mx-auto text-4xl md:text-5xl max-w-2xl leading-tighter tracking-tighter">
        {heading}
      </h1>
      {description && (
        <p className="max-w-3xl mx-auto md:leading-snug leading-tight text-center text-2xl text-gray-600 mb-8">
          {description}
        </p>
      )}
      <div className="max-w-3xl mx-auto mt-12">
        <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
          <div>
            <button
              type="button"
              className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
              onClick={() => history.goBack()}
            >
              Back to Previous Page
            </button>
            <a
              className="block font-bold w-32 text-black my-4 mx-auto"
              href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Web+Support+Issue+via+Error+Page&to=support@beepaudio.com"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Error.propTypes = {
  heading: string,
  description: string
};

Error.defaultProps = {
  heading: 'Oops! Something is not right. Try again or contact us.',
  description: null
};

export default Error;
