import { string } from 'prop-types';
import { Link } from 'react-router-dom';

function CallToAction({ title, description }) {
  return (
    <section className="max-w-6xl mx-auto my-12 px-4 sm:px-6 pb-12 md:pb-20">
      <div className="flex flex-col sm:flex-row bg-black rounded shadow-2xl">
        <div className="flex-grow mb-6 lg:mb-0 py-10 px-8 md:py-16 md:px-12 text-center lg:text-left">
          <h3 className="h3 text-white md:leading-none mb-5 md:mb-0">{title}</h3>
          <p className="text-white leading-none md:leading-snug text-xl my-1 opacity-75">{description}</p>
        </div>
        <div className="flex justify-center items-center bg-effect rounded-b md:rounded-r py-10 px-10 md:py-16 md:px-12">
          <Link to="/create-account" className="btn-lg rounded text-white bg-black transition-all hover:bg-white hover:text-black">
            Let’s Go
          </Link>
        </div>
      </div>
    </section>
  );
}

CallToAction.propTypes = {
  title: string,
  description: string
};

CallToAction.defaultProps = {
  title: 'Want to see if it lives up to the hype?',
  description: 'It only takes 10 seconds. Give it a try for free!'
};

export default CallToAction;
