import React, { useState, useEffect, useContext } from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as IconArrowRight } from '../assets/arrow-right-icon.svg';
import { auth } from '../firebase/firebase.utils';
import { AuthContext } from '../context/AuthContext';

const Header = ({ hideHeaderButtons }) => {
  const [top, setTop] = useState(true);

  const { currentUser, loadingAuthState } = useContext(AuthContext);

  useEffect(() => {
    const scrollHandler = () => (window.pageYOffset > 10 ? setTop(false) : setTop(true));
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && 'bg-white blur shadow-lg'
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-shrink-0 mr-4">
            <Link to="/" className="block" aria-label="Beep">
              <Logo />
            </Link>
          </div>
          {!hideHeaderButtons && (
            <nav className="md:flex md:flex-grow">
              {!loadingAuthState && (
                <ul className="flex flex-grow justify-end flex-wrap items-center">
                  {currentUser ? (
                    <li>
                      <Link
                        to="#"
                        onClick={() => auth.signOut()}
                        className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                      >
                        Log Out
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link
                          to="/log-in"
                          className="btn-sm font-medium rounded border-gray-900 border-2 sm:border-none text-black border-solid border-5 focus:outline-none hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                        >
                          Log in
                        </Link>
                      </li>
                      <li className="hidden sm:block md:list-item">
                        <Link
                          to="/create-account"
                          className="btn-sm text-white bg-gray-900 hover:bg-black ml-3"
                        >
                          Create Account
                          <IconArrowRight className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mt-0.5 -mr-1" />
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  hideHeaderButtons: bool
};

Header.defaultProps = {
  hideHeaderButtons: false
};

export default Header;
