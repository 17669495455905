/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { bool, number, string } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getFormattedDate } from '../utils/utils';
import ProgressBar from './ProgressBar';
import useAudioPlayer from '../utils/useAudioPlayer';
import Modal from './Modal';
import { deleteAudio, handleExpirationDateChange } from '../utils/api';
import PlayerControls from './PlayerControls';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';
import { ReactComponent as ExpirationIcon } from '../assets/expiration-icon.svg';
import { customStyles, selectOptions } from '../utils/select';

const Player = ({
  url,
  filename,
  duration,
  created_at,
  expires_at,
  can_delete,
  tokenId
}) => {
  const [isModalOpen, toggleModalOpen] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [expiration, setExpiration] = useState(expires_at);
  const history = useHistory();

  const {
    elapsedTime,
    isPlaying,
    setPlaying,
    setPlaybackSpeed,
    setAudioPositionValue
  } = useAudioPlayer();

  const isOwner = () => tokenId && can_delete;
  const isLoggedVisitor = () => tokenId && !can_delete;
  const isNotLoggedVisitor = () => !tokenId;

  const formattedCreationDate = getFormattedDate(created_at);

  const handleDelete = async () => {
    const { deleted } = await deleteAudio(filename, tokenId);
    if (deleted) return history.go(0);

    return history.push('/error');
  };

  const handleFileDownload = async () => {
    setDownloadInProgress(true);
    await fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const objectUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', `${filename}.mp3`);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    setDownloadInProgress(false);
  };

  const handleSelectChange = async (option) => {
    setSelectedOption(option);
    const newExpiration = await handleExpirationDateChange(option.value, filename, tokenId);
    setExpiration(newExpiration.expires_at);
  };

  const getExpirationText = () => {
    if (!expiration) return 'Never Expires';

    const time = new Date(expiration * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = time.getFullYear();
    const month = months[time.getMonth()];
    const date = time.getDate();
    return `Expires ${month} ${date}, ${year}`;
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          textContent={{
            heading: 'Delete Recording',
            description:
              'Are you sure you want to permanently delete this recording?'
          }}
          type="warning"
          closeModal={(modalState) => toggleModalOpen(!modalState)}
          handleSubmit={() => handleDelete()}
          submitButtonText="Delete"
        />
      )}
      <div className="mb-40">
        <div className="flex flex-col md:flex-row mx-auto mb-8 p-7 max-w-xl rounded-large shadow-around">
          <div className="w-40 h-40 rounded-large bg-effect mx-auto md:mx-0" />
          <div className="flex flex-grow justify-center flex-col md:ml-8">
            <div className="mt-8 text-center md:text-left md:mt-0">
              <h3 className="text-2xl leading-tighter tracking-tighter">
                Voice Note
              </h3>
              <span className="text-lg text-gray-900 font-light">
                {created_at > 0 && formattedCreationDate}
              </span>
            </div>
            <div>
              <ProgressBar
                handleProgressChange={(time) => setAudioPositionValue(time)}
                duration={duration}
                elapsedTime={elapsedTime}
              />
              <audio id="beep.audio">
                <source src={url} />
                {`Your browser does not support the ${(
                  <code>audio</code>
                )} element.`}
              </audio>
            </div>
            <PlayerControls
              setPlaybackSpeed={setPlaybackSpeed}
              backAudio={() => setAudioPositionValue(elapsedTime - 5)}
              skipAudio={() => setAudioPositionValue(elapsedTime + 5)}
              isPlaying={isPlaying}
              setIsPlaying={(playingValue) => setPlaying(playingValue)}
            />
          </div>
        </div>
        {isOwner() && (
          <div className="flex max-w-xl mx-auto justify-around align-baseline flex-wrap border-dotted border-2 rounded-lg border-gray-300 p-6">
            <div className="flex flex-col mb-4">
              <button
                onClick={() => toggleModalOpen(true)}
                type="button"
                className="mx-auto text-gray-800 hover:text-gray-600 px-2 text-md flex items-center focus:outline-none"
              >
                <DeleteIcon className="mr-1 mb-1 w-4 h-auto" />
                Delete
              </button>
            </div>
            <div className="flex flex-col mb-4">
              <button className="mx-auto text-gray-800 hover:text-gray-600 px-2 text-md flex items-center focus:outline-none" type="button">
                <ExpirationIcon className="mr-1 mb-1 w-4 h-auto" />
                {getExpirationText()}
                <span className="mx-1">|</span>
                <span className="text-blue-600 font-bold">Change</span>
              </button>
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
                options={selectOptions}
                styles={customStyles(null, {
                  opacity: 0,
                  width: '100%',
                  cursor: 'pointer'
                })}
                className="absolute -mt-8"
                isSearchable={false}
                isOptionDisabled={(option) => option.disabled}
              />
            </div>
            <button
              className={`px-2 mr-1 mb-2 text-md flex items-star focus:outline-none ${downloadInProgress ? 'text-gray-400 hover:text-gray-400' : 'text-gray-800 hover:text-gray-600'}`}
              type="button"
              onClick={handleFileDownload}
              disabled={downloadInProgress}
            >
              {downloadInProgress ?
                'Download in progress...' :
                (
                  <>
                    <DownloadIcon className="mr-1 mt-0.5 w-4 h-auto" />
                    Download
                  </>
                )}
            </button>
            <span className="w-full leading-tight text-center text-md text-gray-400 select-none">
              Only visible to you
            </span>
          </div>
        )}
        {isLoggedVisitor() && (
          <div className="max-w-xl mx-auto">
            <div className="flex justify-center items-center mb-4 font-bold">
              <ExpirationIcon className="mr-1 w-4 h-auto" />
              {getExpirationText()}
            </div>
          </div>
        )}
        {isNotLoggedVisitor() && (
          <div className="flex max-w-xl mx-auto justify-around align-baseline flex-wrap">
            {expiration && (
              <div className="flex justify-center items-center mb-8 font-bold">
                <ExpirationIcon className="mr-1 w-4 h-auto" />
                {getExpirationText()}
              </div>
            )}
            <div className="w-full">
              <Link
                to="/create-account"
                className="mx-auto btn text-white bg-black hover:bg-blue-700 w-full sm:w-auto mb-4"
              >
                Reply to Message
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Player.propTypes = {
  url: string.isRequired,
  filename: string.isRequired,
  created_at: number.isRequired,
  duration: number.isRequired,
  can_delete: bool,
  expires_at: number,
  tokenId: string
};

Player.defaultProps = {
  can_delete: false,
  expires_at: null,
  tokenId: null
};

export default Player;
