import { func, number } from 'prop-types';
import { useRef, useState } from 'react';
import { formatTime } from '../utils/utils';

const ProgressBar = ({ elapsedTime, duration, handleProgressChange }) => {
  const formattedElapsedTime = formatTime(elapsedTime);
  const formattedDuration = formatTime(duration);
  const [showKnob, setKnobVisibility] = useState(false);
  const progressBar = useRef(null);

  const toggleProgressBarHover = (visibility) => {
    setKnobVisibility(visibility);
  };

  const calculateClickedTime = (event) => {
    event.stopPropagation();
    const clickedPositionInPage = event.pageX;
    const barStart =
      progressBar.current.getBoundingClientRect().left + window.screenX;
    const barWidth = progressBar.current.offsetWidth;
    const clickPositionInBar = clickedPositionInPage - barStart;
    const timePerPixel = duration / barWidth;

    return timePerPixel * clickPositionInBar;
  };

  const handleKnobChange = (event) => {
    handleProgressChange(calculateClickedTime(event));
  };

  const progressPercentage = Math.min((elapsedTime / duration) * 100, 100);

  return (
    <>
      <div
        className="bar-progress relative w-full h-2 py-2 cursor-pointer"
        onMouseEnter={() => toggleProgressBarHover(true)}
        onMouseLeave={() => toggleProgressBarHover(false)}
        onClick={(event) => handleKnobChange(event)}
      >
        <div
          ref={progressBar}
          className="absolute w-full left-0 border-t-2 border-gray-300"
        />
        <div
          className="absolute flex items-center justify-end rounded-md h-0.5 transition-width duration-150 ease-linear bg-gray-900 cursor-pointer"
          style={{ width: `${progressPercentage}%` }}
        >
          <span
            className={`${
              showKnob ? 'opacity-100' : 'opacity-0'
            } transition-opacity border-8 border-gray-900 rounded-full w-2 h-2`}
          />
        </div>
      </div>
      <div className="flex text-sm justify-between">
        <span>{formattedElapsedTime}</span>
        <span>{formattedDuration}</span>
      </div>
    </>
  );
};

ProgressBar.propTypes = {
  handleProgressChange: func.isRequired,
  elapsedTime: number.isRequired,
  duration: number.isRequired
};

export default ProgressBar;
