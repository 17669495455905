import { bool, node } from 'prop-types';
import { ReactComponent as IconGoogle } from '../assets/google-icon.svg';

const CustomButton = ({ children, isGoogleSignIn, ...otherProps }) => (
  <button type="button" className="bg-black rounded shadow-xl focus:outline-none p-3 sm:p-4 text-white text-sm sm:text-lg mx-auto flex items-center" {...otherProps}>
    {isGoogleSignIn && <IconGoogle className="mx-2 mb-1" />}
    {children}
  </button>
);

CustomButton.propTypes = {
  children: node,
  isGoogleSignIn: bool
};

CustomButton.defaultProps = {
  children: null,
  isGoogleSignIn: false
};

export default CustomButton;
