import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import PageHeading from '../components/PageHeading';
import { AuthContext } from '../context/AuthContext';
import { deleteAccount } from '../utils/api';

const STEP_PROCESSING = 'processing';
const STEP_ERROR = 'error';
const STEP_SUCCESS = 'success';

const MODAL_STATUS = {
  [STEP_PROCESSING]: {
    type: 'info',
    heading: 'Processing Account Deletion',
    description: 'Please wait while we process your request.',
    hideButtons: true,
    alignVertically: false
  },
  [STEP_ERROR]: {
    type: 'warning',
    heading: 'An Error Occurred',
    description:
      'An error occurred while trying to delete the account. Please try again.',
    hideButtons: false,
    alignVertically: false
  },
  [STEP_SUCCESS]: {
    type: 'success',
    heading: 'Your Account Has Been Deleted',
    description: 'Your account and all of your recordings have been deleted.',
    hideButtons: false,
    alignVertically: true
  }
};

const DeleteAccount = () => {
  const history = useHistory();
  const [isConfirmationModalOpen, setModalOpen] = useState(false);
  const [isStepModalOpen, setStepModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEP_PROCESSING);
  const { currentUser, tokenId, setUser } = useContext(AuthContext);

  const handleAccountDeletion = async () => {
    setModalOpen(false);
    setCurrentStep(STEP_PROCESSING);
    setStepModalOpen(true);
    const callApi = await deleteAccount(tokenId);

    if (callApi?.error) {
      return setCurrentStep(STEP_ERROR);
    }

    return setCurrentStep(STEP_SUCCESS);
  };

  const handleAccountDeleted = () => {
    setUser(null);
    history.push('/');
  };

  const currentStepData = MODAL_STATUS[currentStep];

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageHeading
        title="Account Deletion"
        description={['Click below to permanently delete your account']}
      />
      {isConfirmationModalOpen && (
        <Modal
          textContent={{
            heading: `Permanently Delete Beep Account ${currentUser.email}`,
            description:
              'Are you sure you want to permanently delete your account? This is irreversible and all your recordings will be deleted.'
          }}
          type="warning"
          closeModal={() => setModalOpen(false)}
          handleSubmit={() => handleAccountDeletion()}
          submitButtonText="Delete Account"
          closeBtnText="Cancel"
        />
      )}
      {isStepModalOpen && (
        <Modal
          textContent={{
            heading: currentStepData.heading,
            description: currentStepData.description
          }}
          type={currentStepData.type}
          alignVertically={currentStepData.alignVertically}
          hideButtons={currentStepData.hideButtons}
          closeModal={
            currentStep === STEP_SUCCESS
              ? () => handleAccountDeleted()
              : () => setStepModalOpen(false)
          }
        />
      )}
      <button
        className="bg-red-500 text-white p-4 rounded-md block mx-auto my-4 hover:bg-red-700"
        type="button"
        onClick={() => setModalOpen(true)}
      >
        Delete Account
      </button>
      <button className="mb-24" type="button" onClick={() => history.push('/')}>
        ...or keep account
      </button>
    </Layout>
  );
};

export default DeleteAccount;
