import Layout from '../components/Layout';
import TermsBuilder from '../components/TermsBuilder';

const PrivacyPolicy = () => (
  <Layout
    title="Beep - Privacy Policy"
    description="Beep respects your privacy. Read the most recent privacy policy here."
    extendClass="max-w-3xl text-lg text-justify"
  >
    <TermsBuilder title="Privacy Policy" date="Effective August 12, 2022">
      <TermsBuilder.Section>
        <TermsBuilder.Text>
          This privacy policy sets out how JoWoCo, LLC (“
          <strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>
          ” or “<strong>our</strong>”) collects, uses and protects any
          information that you give us when you use our websites, mobile apps,
          social media pages, or any other products or services offered by us (“
          <strong>Services</strong>”).
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Collection of Your Information</TermsBuilder.H2>
        <TermsBuilder.Text>
          We collect or receive information about your interactions with us.
          Depending on how you use our Services, your information may include,
          without limitation:
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            registration-related information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            transaction-related information (such as credit card or other
            preferred means of payment, billing information, credit card number,
            expiration date, billing address and/or a history of purchases
            through our Services);
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            customer service information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>location data;</TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            information about any devices, connections and methods used to
            access and interact with us;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            information based on your usage of our Services (“
            <strong>Usage Data</strong>”);
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>How Your Information may be used</TermsBuilder.H2>
        <TermsBuilder.Text>
          Your information may be used for purposes that include, without
          limitation:
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            to operate and improve the Services available through us;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            to fulfill your requests for tools, software, functionality,
            features and other products, and services;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            to communicate with you and respond to your inquiries;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            to conduct research about your use of our products; and
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            to provide customer support and technical assistance;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            to personalize the content and advertisements provided to you
            (including to present offers to you on behalf of business partners
            and advertisers);
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            for administration of your account;
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Cookies</TermsBuilder.H2>
        <TermsBuilder.Text>
          We may use cookies and/or other technologies along with your
          information to enhance and personalize your experience and gather
          information that helps us optimize our Services. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. However, it is important to
          remember that many of our Services may not function properly if your
          cookies are disabled.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Our third party vendors and/or services may use their own cookies
          and/or other third-party cookies together (subject to their own
          privacy policies) to (a) inform, optimize, and serve ads across the
          web based on your past visits to our Services and others and (b)
          report to us how your ad impressions, other uses of ad services, and
          interactions with these ad impressions and ad services are related to
          visits to our site. If and to the extent from time to time we use
          Double Click or another Google brand for ad serving, analytics,
          remarketing, retargeting, etc., you can set certain preferences and
          opt-outs using{' '}
          <a
            href="https://www.google.com/settings/ads"
            target="_blank"
            rel="nofollow noreferrer"
            className="ml-1 text-blue-500"
          >
            Google Ads Settings
          </a>
          , and Google Analytics’
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="nofollow noreferrer"
            className="ml-1 text-blue-500"
          >
            currently available opt-outs
          </a>
          , found here
          <a
            href="https://www.google.com/ads/preferences"
            target="_blank"
            rel="nofollow noreferrer"
            className="ml-1 text-blue-500"
          >
            https://www.google.com/ads/preferences/
          </a>{' '}
          https://www.google.com/ads/preferences/ and here
          <a
            href="https://adssettings.google.com"
            target="_blank"
            rel="nofollow noreferrer"
            className="ml-1 text-blue-500"
          >
            https://adssettings.google.com/
          </a>
          .
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          Links to Other Websites, Apps and Services
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          Our websites and other Services may contain links to other third-party
          websites, apps and services of interest. However, once you have used
          these links to leave our site, you should note that we have no control
          over such third-party services. You should exercise caution and should
          review the privacy statement(s) applicable to the third-party sites
          and services in question.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Sharing of Your Information</TermsBuilder.H2>
        <TermsBuilder.Text>
          {' '}
          We do not rent or sell your personally identifiable information (such
          as name, address, telephone number and credit card information) to
          unaffiliated third parties for their marketing purposes. We may share
          your information with third parties to provide products and services
          you have requested, when we have your consent, or as described in this
          Privacy Policy.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          We may share your personal information and usage data to any
          affiliated entities we may have, including our subsidiaries. We may
          share aggregated, non-personally identifiable information, publicly
          and with our partners like publishers, advertisers or connected sites.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          The contents of your online communications, as well as other
          information about you as a user of our Services, may be accessed and
          disclosed under the following circumstances: in response to lawful
          governmental requests or legal process (for example, a court order,
          search warrant or subpoena), in other circumstances in which we have a
          good-faith belief that a crime has been or is being committed by a
          user of our Services, that an emergency exists that poses a threat to
          the safety of you or another person, when necessary to protect either
          our rights or our property or for us to render the service you have
          requested.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Many of our Services let you share information with others. When
          shared privately, we cannot guarantee it is secure. Always use caution
          in deciding what content you post, and do not post or store any
          information you would not want others to see.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Our offerings may include features or functionalities provided by
          third parties, or may integrate or interact with third-party products
          and services. For example, we may use a third party (such as Google,
          Clicky, Firebase, etc.) for analytics, performance monitoring, bug
          tracking, ad serving, retargeting, and/or remarketing, in which case
          such third party may have access to your data, subject to its
          policies. In the process of providing such functionalities within our
          services, your browser or other tools or technology may send certain
          information to such third party providers and/or services. The use of
          these third-party-provided features or functionalities is subject to
          their own privacy policies. See our Terms of Service for more details.
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          We may provide access to your personal information and usage data to
          our trusted service providers that assist us with the operation and
          maintenance of our sites and services.
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          In the event that ownership of us was to change as a result of a
          merger, acquisition, or transfer to another company, your information
          may be transferred. If such a transfer results in a material change in
          the use of your information, you will be provided notice (which may be
          via updates to this page) about your ability to decline to permit such
          a transfer.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          If we sell or otherwise transfer part or all of our business or assets
          to another organization, such as in the course of an acquisition,
          merger, bankruptcy or liquidation, we may transfer your personal
          information and Usage Data.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          We may participate with another company or individual for purposes of
          jointly promoting our products, services, promotions or contests or
          their products, services, promotions or contests. We may disclose your
          personal information to them for purposes of (i) compensation,
          transaction processing, fulfillment and support, and (ii) for purposes
          of offering you other products, services, promotions and contests.
          These joint venture marketing partners may also contact you about
          other products, services, promotions or contests.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          We reserve the right to disclose your personal information and Usage
          Data if we have a good-faith belief that access, use, preservation or
          disclosure of such information is reasonably necessary (i) to satisfy
          any applicable law, regulation, legal process or enforceable
          governmental request (such as for example, to comply with a subpoena
          or court order), (ii) to detect, prevent, and address fraud or other
          illegal activity and (iii) to investigate, respond to, or enforce
          violations of our rights or the security of our sites and services.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Display of Advertising</TermsBuilder.H2>
        <TermsBuilder.Text>
          Your information may be used for the presentation of advertisements.
          We may use ad networks to customize and display advertising on our
          Services. We may share certain information about you as a user (such
          as age, zip code or other information we have collected or received)
          with certain ad network and service providers to help them deliver
          more relevant content and advertisements through their networks. We
          may from time to time work with other companies for certain services
          such as analytics or advertising, and you may be subject to their
          privacy policies as well, though you may opt out through them directly
          or contact us with questions.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Your Choices about Your Information</TermsBuilder.H2>
        <TermsBuilder.Text>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            Whenever you are asked to fill in a form on our website or our other
            Services, consider what information to include and exclude;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            We may provide you with access to your registration information and
            the ability to edit this information in your account settings
            dashboard and profile pages. Please be aware that if and to the
            extent any information is indexable by search engines (including
            public profile information), it may not be updated by such search
            engines when we update it;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Some of our Services may provide you with additional information and
            choices about your privacy, which you should review.
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            If you have previously agreed to our using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to us using the contact information below.
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Our Commitment to Security</TermsBuilder.H2>
        <TermsBuilder.Text>
          We have established safeguards to help prevent unauthorized access to
          or misuse of your information, but cannot guarantee that your
          information will never be processed, used or disclosed in a manner
          inconsistent with this Privacy Policy (for example, as a result of
          unauthorized acts by third parties that violate applicable law or our
          and our affiliated providers&apos; policies). To protect your privacy
          and security, we may use passwords or other technologies to register
          or authenticate you and enable you to take advantage of our Services,
          and before granting access or making corrections to your information.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          YOUR CALIFORNIA PRIVACY RIGHTS – California Consumer Privacy Act
        </TermsBuilder.H2>
        <TermsBuilder.Text>For California residents:</TermsBuilder.Text>
        <TermsBuilder.Text>
          We may share your personal information with third parties and
          affiliated third parties (such as local, state and regional affiliates
          and affiliate alliances), but they do not share your name for their
          direct marketing purposes. As these third parties and this category of
          affiliated third parties are considered an <i>unaffiliated party</i>{' '}
          under California law, you may opt-out of our disclosure of personal
          information to third parties for their direct marketing purposes. To
          opt out, please contact us as described in the “How to Contact Us”
          Section below.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          We will continue to provide your information to local, state and
          regional affiliates and affiliate alliances identified in your
          membership application or account for the purpose of processing your
          membership in such affiliate and affiliate alliance programs.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          The California Consumer Privacy Act (CCPA), effective January 1, 2020,
          gives California consumers enhanced rights with respect to their
          personal information that is collected by businesses. First,
          California consumers may opt out of having their personal information
          sold to other persons or parties. Second, they have a right to know:
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem extendClass="list-none">
            1. What specific pieces of information a business has about the
            consumer;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem extendClass="list-none">
            2. Categories of personal information it has collected about the
            consumer;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem extendClass="list-none">
            3. Categories of sources from which the personal information is
            collected;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem extendClass="list-none">
            4. Categories of personal information that the business sold or
            disclosed for a business purpose about the consumer;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem extendClass="list-none">
            5. Categories of third parties to whom the personal information was
            sold or disclosed for a business purpose; and
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem extendClass="list-none">
            6. The business or commercial purpose for collecting or selling
            personal information.
          </TermsBuilder.ListItem>
        </TermsBuilder.List>

        <TermsBuilder.Text>
          {' '}
          In addition, California consumers can request that the personal
          information a business has collected about them be deleted from the
          business’s systems and records.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Company may be considered a covered business under the CCPA as it
          collects and processes the personal information of California
          consumers. This Privacy Policy provides the required notices to
          California consumers. The CCPA also prohibits covered businesses from
          providing discriminatory treatment to California consumers if they
          exercise their rights under the Act.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          We do not rent or sell your personally identifiable information (such
          as name, address, telephone number and credit card information) to
          unaffiliated third parties for their marketing purposes. We may share
          your information with third parties to provide products and services
          you have requested, when we have your consent, or as described in this
          Privacy Policy.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          To make a “request to know” or request to delete your personal
          information, send us an e-mail at
          <a href="mailto:info@beepaudio.com" className="ml-1 text-blue-500">
            info@beepaudio.com
          </a>{' '}
          (Please put either “Request to Know” or “Request to Delete” in the
          subject heading of your email.) We will use commercially reasonable
          efforts to honor these requests whether or not you would qualify as a
          California consumer under the CCPA.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          If and to the extent we are considered a covered business under the
          CCPA: We will confirm receipt of your request within 10 days along
          with a description of what steps we will take to verify and respond.
          We must provide the requested information or delete your personal
          information within 45 days of receipt of your request but can use an
          additional 45 days, but we must let you know the additional time is
          needed.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          When contacting us, we may ask you to provide certain, limited
          personal information, such as your name, email address and/or account
          login ID and/or password, to verify your request and to match with our
          records and systems. This is also to protect against fraud. We will
          not retain this personal information or use it for any other purpose.
          Also please be advised that we need to search our records and systems
          only for the preceding 12 months.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Residents of other states may also have similar rights to request
          information about or delete their personal information. To inquire
          about exercising these rights, please contact us at
          <a href="mailto:info@beepaudio.com" className="ml-1 text-blue-500">
            info@beepaudio.com
          </a>
          .
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Pursuant to California’s “Shine The Light law (California Civil Code §
          1798.983), California residents are entitled, once a year and free of
          charge, to request the disclosure of certain categories of personal
          information to third parties for their own direct marketing purposes
          in the preceding calendar year, if any. Under the law, a business
          should either provide California customers certain information upon
          request or permit California customers to opt out of this type of
          sharing. You may request this information by contacting us at
          <a href="mailto:info@beepaudio.com" className="ml-1 text-blue-500">
            info@beepaudio.com
          </a>{' '}
          and indicate in the email subject line, “California Shine The Light
          Request.” Please include your mailing address, state of residence and
          email address with your request.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          Your rights as a visitor from the European Economic area (EEA)
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          If you are a European resident, our legal basis for collecting and
          using your personal data or information is to do so with your consent;
          where we need the personal data or information for performance of a
          contract, or where the collection and use is in our legitimate
          interests and not overridden by your data protection interests or
          fundamental rights and freedoms. In some cases, we may also have a
          legal obligation to collect the personal information in question. If
          we collected your personal data or information with your consent, you
          may withdraw your consent at any time.
        </TermsBuilder.Text>
        <TermsBuilder.Text>You also have the right to:</TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            Access your personal data or information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Delete, or request deletion of, your personal data or information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Object to or restrict processing of your personal information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Request portability of your personal information;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Complain to your local data protection authority at any time;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Object to automated decision making; and
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Update your personal data or information.
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
        <TermsBuilder.Text>
          To withdraw consent or exercise these rights, please contact us as
          described in the “How to Contact Us” Section below.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Withdrawing your consent will not affect the lawfulness of any
          processing we conducted prior to your withdrawal, nor will it affect
          processing of your personal information conducted in reliance on
          lawful processing grounds other than consent.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          If we ask you to provide personal data to us to comply with a legal
          requirement or enter into a contract, we will inform you of this and
          let you know whether providing us with your personal data is required
          and if not, the consequences of not sharing your personal data with
          us.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Similarly, if we collect and use your personal information in reliance
          on our or a third party&apos;s legitimate interests and those
          interests are not already listed above (see &quot;Information
          Use&quot; section), we will let you know what those legitimate
          interests are.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          DATA STORAGE AND RETENTION (International Transfers)
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          Your personal information may be stored on servers in the United
          States and may also be stored or processed in other countries by our
          service providers, if and to the extent compliant with law. You
          understand and agree that We may collect, use, disclose, and otherwise
          process the information you provide as described in this Privacy
          Statement even if you are from an area outside the United States. Your
          personal information may be disclosed in response to inquiries or
          requests from government authorities or to respond to judicial process
          in the United States. We will retain your personal information for as
          long as it is needed to provide you with the Services, or to fulfill
          any legal or contractual obligations we may have, or as otherwise
          permitted herein and by law.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          If you are a resident of the EEA: Your personal information may be
          transferred to and processed in the United States, which has data
          protection laws that are different than those in your country and may
          not be as protective.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          DO NOT TRACK DISCLOSURES (EEA and California)
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          Some web browsers incorporate a &quot;Do Not Track&quot; feature that
          signals to websites and Services that you visit that you do not want
          to have your online activity tracked. Each browser communicates
          &quot;Do Not Track&quot; signals to websites differently, making it
          unworkable to honor each and every request correctly. In order to
          alleviate any communication error between browsers and our Services,
          we do not respond to or honor &quot;Do Not Track&quot; signals at this
          time. As the technology and communication between browser and website
          improves, we may elect in our discretion to reevaluate the ability to
          honor &quot;Do Not Track&quot; signals and may make changes to our
          policy. You may adjust your browser or operating system settings to
          limit this tracking or to decline cookies, but by doing so, you may
          not be able to use certain features on the Services or take full
          advantage of all of our offerings. Check the &quot;Help&quot; menu of
          your browser or operating system to learn how to adjust your tracking
          settings or cookie preferences. To learn more about the use of cookies
          or other technologies to deliver more relevant advertising and your
          choices about not having this information used by certain Service
          Providers (defined below), please click here. On your mobile device,
          you can adjust your privacy and advertising settings to limit your
          tracking for advertising or control whether you receive more relevant
          advertising. Note that our systems may not recognize Do Not Track
          headers or requests from some or all browsers.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>How to Contact Us</TermsBuilder.H2>
        <TermsBuilder.Text>
          If you have any questions or concerns about this Privacy Policy or its
          implementation, you may contact us at
          <a href="mailto:info@beepaudio.com" className="ml-1 text-blue-500">
            info@beepaudio.com
          </a>
          . When updating your personal information, we may ask you to verify
          your identity before we can act on your request. We may reject
          requests that are unreasonably repetitive, require disproportionate
          technical effort (for example, developing a new system or
          fundamentally changing an existing practice), risk the privacy of
          others, or would be extremely impractical (for instance, requests for
          information residing on backup tapes).
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>Changes to this Privacy Policy</TermsBuilder.H2>
        <TermsBuilder.Text>
          We may modify or update this Privacy Policy from time to time in our
          sole discretion, and so you should review this policy periodically.
          Your continued use of any Services constitutes your acceptance of any
          such changes.
        </TermsBuilder.Text>
      </TermsBuilder.Section>
    </TermsBuilder>
  </Layout>
);
export default PrivacyPolicy;
