import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import FileLoader from '../components/FileLoader';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import PageLoader from '../components/PageLoader';
import Player from '../components/Player';
import PromotionalBanner from '../components/PromotionalBanner';
import { AuthContext } from '../context/AuthContext';
import retrieveBeep from '../utils/api';

const Play = () => {
  const { audioId } = useParams();
  const { tokenId, loadingAuthState } = useContext(AuthContext);
  const { audio, fetchingAudio, error } = retrieveBeep(audioId, tokenId, {
    revalidateOnFocus: false
  });

  if (loadingAuthState) {
    return <PageLoader />;
  }

  const getPageHeading = () => {
    if (error) {
      return {
        title: 'Recording Unavailable',
        description: ['This recording is expired or has been deleted or there is a system error.']
      };
    }
    return {
      title: 'You were left a beep',
      description: ['Turn up your volume and press play.']
    };
  };

  return (
    <Layout
      title="Listen to this Voice Note - Beep"
      description="You were left a voice note. Play it now!"
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageHeading {...getPageHeading()} />
      {fetchingAudio && <FileLoader />}
      {audio && <Player {...audio} tokenId={tokenId} />}
      <PromotionalBanner />
    </Layout>
  );
};

export default Play;
