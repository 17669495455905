import Layout from '../components/Layout';
import TermsBuilder from '../components/TermsBuilder';

const Terms = () => (
  <Layout
    title="Beep - Terms"
    description="Read our most recent terms of service here."
    extendClass="max-w-3xl text-lg text-justify"
  >
    <TermsBuilder title="Terms of Service" date="Effective August 12, 2022">
      <TermsBuilder.Section>
        <TermsBuilder.Text>
          These Terms of Service (&quot;Terms&quot;) are an agreement between
          you (&quot;you&quot;) and JoWoCo, LLC (including without limitation
          its brands, such as Beep (&quot;Company&quot;, &quot;Beep&quot;,
          &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) that governs your
          use of our website(s) and/or other products and services
          (collectively, as applicable, the &quot;Services&quot;).
        </TermsBuilder.Text>
        <TermsBuilder.Text extendClass="underline font-bold">
          IF YOU ARE HAVING AN EMERGENCY, CONTACT 911, AND DO NOT USE THIS
          SERVICE.
        </TermsBuilder.Text>

        <TermsBuilder.H3>To Use Our Services, You:</TermsBuilder.H3>

        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            May need to register with us to access and use some of our Services
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Must not initiate or participate in any activities on our Services
            that are illegal, harmful, or interfere with anyone&apos;s use of
            our services, including the sending of e-mail or instant messaging
            spam
          </TermsBuilder.ListItem>
        </TermsBuilder.List>

        <TermsBuilder.H3>
          If You Post Content On our Services, You:
        </TermsBuilder.H3>

        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            May post content that you create or have been given permission to
            post by the owner, is legal, and doesn&apos;t violate this Agreement
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Are responsible for content, data and information that you post to
            our Services and assume all risks of posting personal information
            online
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Continue to own the content but grant us a license to use and
            distribute your content, subject to and in accordance with this
            Agreement.
          </TermsBuilder.ListItem>

          <TermsBuilder.Text>
            <strong>
              You must be at least 18 years of age, and, where law requires an
              older legal age, of legal age for contractual consent or older to
              use this website and/or the other Services. If you are younger,
              you must have consent and approval of your parent or guardian to
              use the website and enter this Agreement, and you must ensure they
              have seen and agreed to these terms
            </strong>
            . Due to the age restrictions, no content or information from the
            Services falls within the Child Online Privacy Protection Act
            (“COPPA”) and is not monitored as doing so. If you are a teacher or
            representative from a school, you are responsible for obtaining
            parental consent to use the Services to interact with students. If
            you are a student under 13, please do not send any personal
            information about yourself to us if your school, school district or
            teacher has not obtained this prior consent from your parent or
            guardian, or we have not obtained such consent, and please do not
            send any personal information other than what we request from you in
            connection with the Services.
          </TermsBuilder.Text>
        </TermsBuilder.List>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>1. PASSWORDS AND ACCESS</TermsBuilder.H2>
        <TermsBuilder.Text>
          1.1. In order to access some features of the Services, you may have to
          register or create an account. You may not use another&apos;s account
          without permission. When creating your account, you must provide
          accurate and complete information. Registration or subscription to the
          Services and payment of any applicable fee, authorizes a single
          individual to use the Services unless otherwise expressly stated. You
          are solely responsible for the activity that occurs on your account,
          and you must keep your account password secure. You must notify
          Company immediately of any breach of security or unauthorized use of
          your account. Although Company will not be liable for your losses
          caused by any unauthorized use of your account, you may be liable for
          the losses of Company or others due to such unauthorized use.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          1.2. You shall not download any content, software or services unless
          you see a “download” or similar link displayed by Company on the
          Services for that content. You shall not copy, reproduce, distribute,
          transmit, broadcast, display, sell, license, or otherwise exploit any
          content for any other purposes without the prior written consent of
          Company or the respective licensors of the content. Company and its
          licensors reserve all rights not expressly granted in and to the
          Services and their content.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          1.3. You agree not to circumvent, disable or otherwise interfere with
          security-related features of the Services or features that prevent or
          restrict use or copying of any content or enforce limitations on use
          of the Services or the content therein.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>2. YOUR OBLIGATIONS</TermsBuilder.H2>
        <TermsBuilder.Text>You may not:</TermsBuilder.Text>
        <TermsBuilder.Text>
          1. restrict or inhibit any other user from using and enjoying the
          Services;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          2. post or transmit any unlawful, obscene, pornographic, vulgar,
          sexually-orientated, profane, threatening, abusive, hateful, offensive
          or otherwise objectionable information of any kind, including any
          transmissions constituting or encouraging conduct that would
          constitute a criminal offense, give rise to civil liability, or
          otherwise violate any local, state, national or foreign law;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          3. post or transmit any advertisements, solicitations, chain letters,
          pyramid schemes, investment opportunities or schemes or other
          unsolicited commercial communication (except as otherwise expressly
          permitted by Company) or engage in spamming or flooding;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          4. post or transmit any information or software which contains a
          virus, Trojan horse, worm or other harmful component;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          5. upload, post, publish, reproduce, transmit or distribute in any way
          any component of the Services itself or derivative works with respect
          thereto;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          6. resell or otherwise exploit for commercial purposes, directly or
          indirectly, any portion of the Services, or access to them;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          7. use email addresses obtained from the Services for solicitation
          purposes of any kind, directly or indirectly;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          8. use data mining, robots or other similar data gathering and
          extraction tools;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          9. access (or attempt to access) the Services or any content therein
          through any automated means (including use of any script or web
          crawler, page-scrape, spider, robot, index, Internet agent or other
          automatic device, program, algorithm or technology which does the same
          things) including without limitation, to use, access, copy, acquire
          information, generate impressions, input information, store
          information, search, generate searches or monitor any portion of this
          website;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          10. use the Services in any manner that could damage, disable,
          overburden, or impair our servers or interfere with any other
          party&apos;s use and enjoyment of the Services;
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          ANY USE OF THE SERVICES BY ANY PARTY, INCLUDING YOU, IN ANY HIGH RISK
          APPLICATION IS DONE AT THE USER&apos;S OWN RISK, WITHOUT ANY WARRANTY.
          AS USED HEREIN, A “HIGH RISK APPLICATION” IS ANY USE WHERE THE FAILURE
          OF THE SERVICE COULD CAUSE SERIOUS RISK, INCLUDING RISK OF INJURY TO
          PERSONS OR PROPERTY, OR A LIFE-THREATENING SITUATION, INCLUDING BUT
          NOT LIMITED TO, MEDICAL, NUCLEAR, AVIATION, NAVIGATION, EMERGENCY
          SERVICES, EMERGENCY WEATHER ALERTS, MILITARY APPLICATIONS OR OTHER
          SIMILAR APPLICATIONS.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          3. PUBLIC POSTINGS AND LICENSED MATERIALS
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          3.1. Certain material you may post on our Services is or may be
          available to the public, including without limitation any public
          profile data, feedback, questions, comments, suggestions, uploads,
          blog entries, ratings, reviews, images, videos, poll answers, etc., in
          any form or media, that you post via the Services or otherwise
          (collectively, &quot;Public Postings&quot;). These Public Postings
          will be treated as non-confidential and nonproprietary. You are
          responsible for any Public Postings and the consequences of sharing or
          publishing such content with others or the general public. This
          includes, for example, any personal information, such as your address,
          the address of others, or your current location. WE ARE NOT
          RESPONSIBLE FOR THE CONSEQUENCES OF PUBLICLY SHARING OR POSTING ANY
          PERSONAL OR OTHER INFORMATION ON THE SERVICES.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          3.2. Other content or communications you transmit to us, including
          without limitation any feedback, data, questions, comments,
          suggestions, in any form or media, that you submit to us via e-mail,
          the Services or otherwise (to the extent excluding any personally
          identifiable information, collectively, &quot;Submissions&quot;), will
          be treated as non-confidential and nonproprietary.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          3.3. By providing any Public Posting or Submission, you (i) grant to
          Company a royalty-free, non-exclusive, perpetual, irrevocable,
          sub-licensable right to use, reproduce, modify, adapt, publish,
          translate, create derivative works (including products) from,
          distribute, and display such content throughout the world in all media
          and you license to us all patent, trademark, trade secret, copyright
          or other proprietary rights in and to such content for publication on
          the Services pursuant to this Agreement; (ii) agree that we shall be
          free to use any ideas, concepts or techniques embodied therein for any
          purpose whatsoever. In addition, you hereby waive all moral rights you
          may have in any Public Posting or Submissions.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          3.4. You shall be solely responsible for your own content and any
          Public Postings and Submissions. You affirm, represent, and warrant
          that you own or have the necessary licenses, rights, consents, and
          permissions to publish content you post or submit. You further agree
          that content you submit via Public Postings or Submissions will not
          contain third party copyrighted material, or material that is subject
          to other third party proprietary rights, unless you have permission
          from the rightful owner of the material or you are otherwise legally
          entitled to post the material and to grant us all of the license
          rights granted herein.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          3.5. We do not endorse any content submitted to the Services by any
          user or other licensor, or any opinion, recommendation, or advice
          expressed therein, and we expressly disclaim any and all liability in
          connection with content.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Company has no obligation to monitor the Services. However, you
          acknowledge and agree that Company has the right to monitor the
          Services electronically from time to time, and to disclose any
          information as necessary or appropriate to satisfy any law, regulation
          or other governmental request, to operate the Services properly, or to
          protect itself or its customers. Company reserves the right to refuse
          to post or to remove any information or materials, in whole or in
          part, that, in its sole discretion, are unacceptable, undesirable,
          inappropriate or in violation of this Agreement.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>4. FEES AND PAYMENTS</TermsBuilder.H2>
        <TermsBuilder.Text>
          4.1. If and to the extent any portion of the Services may require a
          fee payment or incremental payment or subscription, you agree to pay
          Company any applicable fees posted for the Services. You agree to pay
          us for all charges incurred under your account, including all
          applicable taxes, fees, and surcharges. If we do not receive payment
          from your designated payment method or any other payment method on
          file, you agree to pay all amounts due upon demand by us. You will be
          responsible for accrued but unpaid charges, even if your account is
          canceled by you or terminated by us. During any free trial or other
          promotion, if any, you will still be responsible for any purchases and
          surcharges incurred using your account.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          4.2. After 30 days from the date of any unpaid charges, your fee-based
          Services will be deemed delinquent and we may terminate or suspend
          your account and Services for nonpayment. We reserve the right to
          assess an additional 1.5 percent late charge (or the highest amount
          allowed by law, whichever is lower) per month if your payment is more
          than 30 days past due and to use any lawful means to collect any
          unpaid charges. You are liable for any fees, including attorney and
          collection fees, incurred by us in our efforts to collect any
          remaining balances from you. Except as otherwise mutually agreed in
          writing, we reserve the right to change our fees with 30 days’ notice.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          4.3. You are responsible for all charges incurred under your account,
          including applicable taxes, fees, surcharges, and purchases made by
          you or anyone you allow to use your account (including your children,
          family, friends, or any other person with implied, actual, or apparent
          authority) or anyone who gains access to your account as a result of
          your failure to safeguard your username, password, or other
          authentication credentials or information.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          5. WARRANTIES AND LIMITATIONS OF WARRANTIES.
        </TermsBuilder.H2>
        <TermsBuilder.Text>
          5.1. If you are not completely satisfied with the Services, your sole
          remedy is to cease using the Services. With respect to any fee-based
          Services, if you signed up for designated term or timeframe, you will
          still be responsible for payment for the full term. If you did not
          subscribe for any minimum period, then you may cancel at any time on
          30 days advanced notice, and cease to use the Services, then you will
          not be charged any additional amounts after the effective date of such
          termination. In any case, you will be responsible for any and all
          charges and activity accrued prior to your Services termination date,
          and those obligations will survive your termination of the Services.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          5.2. THE SERVICES, THE WEBSITE, APPS, AND ALL INFORMATION, CONTENT,
          AND MATERIALS RELATED TO THE FOREGOING, ARE PROVIDED &quot;AS
          IS.&quot; WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, NON-INTERFERENCE, SYSTEM
          INTEGRATION AND ACCURACY OF DATA. WE DO NOT WARRANT THAT USE OF THE
          SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR VIRUS FREE. ALTHOUGH
          INFORMATION THAT YOU SUBMIT MAY BE PASSWORD PROTECTED, WE DO NOT
          GUARANTEE THE SECURITY OF ANY INFORMATION TRANSMITTED TO OR FROM THE
          SERVICES AND YOU AGREE TO ASSUME THE SECURITY RISK FOR ANY INFORMATION
          YOU PROVIDE THROUGH THE SERVICES.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>6. LIMITATIONS OF LIABILITY.</TermsBuilder.H2>
        <TermsBuilder.Text>
          6.1. IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE FOR ANY
          INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, OR FOR LOSS OF
          PROFITS OR DAMAGES ARISING DUE TO BUSINESS INTERRUPTION OR FROM LOSS
          OR INACCURACY OF INFORMATION, INCLUDING IF AND TO THE EXTENT ANY OF
          THE FOREGOING ARISES IN CONNECTION WITH THIS AGREEMENT OR YOUR USE OR
          INABILITY TO USE THE SERVICES. IN NO EVENT WILL THE AGGREGATE
          LIABILITY OF US TO YOU FOR ANY AND ALL CLAIMS ARISING IN CONNECTION
          WITH THIS AGREEMENT OR THE SERVICES, EXCEED THE TOTAL FEES PAID TO US
          BY YOU, IF ANY, DURING THE SIX-MONTH PERIOD PRECEDING THE DATE OF ANY
          CLAIM (OR $10 IF THE SERVICES ARE FREE).
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          6.2. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US AND OUR
          AFFILIATED COMPANIES, AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS,
          DIRECTORS, MEMBERS, AGENTS, AND EMPLOYEES FROM AND AGAINST ALL LOSSES,
          EXPENSES, DAMAGES, CLAIMS, JUDGMENTS, COSTS, EXPENSES AND LIABILITIES,
          INCLUDING REASONABLE ATTORNEYS&apos; FEES, INCURRED BY US OR SUCH
          PARTIES AND/OR ARISING OUT OF OR RESULTING FROM (1) ANY ACTUAL OR
          ALLEGED VIOLATION BY YOU OF THIS AGREEMENT (INCLUDING ANY
          REPRESENTATION OR WARRANTY HEREIN); (2) ANY ACTIVITY RELATED TO YOUR
          ACCOUNT BY YOU OR ANY OTHER PERSON ACCESSING THE SERVICE WITH YOUR
          PASSWORD; (3) YOUR USE OF AND ACCESS TO THE SERVICES; (4) YOUR ACTUAL
          OR ALLEGED VIOLATION OF ANY THIRD PARTY RIGHT, INCLUDING WITHOUT
          LIMITATION ANY COPYRIGHT, PROPERTY OR PRIVACY RIGHT; (5) YOUR ACTUAL
          OR ALLEGED VIOLATION OF ANY LAW, RULE OR REGULATION; AND/OR (6) YOUR
          CONTENT OR DATA, INCLUDING IF IT CAUSES ANY DAMAGE TO A THIRD PARTY.
          YOUR DEFENSE, INDEMNIFICATION AND HOLD HARMLESS OBLIGATIONS IN THIS
          AGREEMENT WILL SURVIVE THIS AGREEMENT AND YOUR USE OF THE SERVICES.
        </TermsBuilder.Text>

        <TermsBuilder.Text>
          6.3. We shall not be liable for any circumstances arising out of
          causes beyond our reasonable control or without our fault or
          negligence, including, but not limited to, Acts of God, acts of civil
          or military authority, fires, riots, wars, embargoes, Internet
          disruptions, hacker attacks, or communications failures, or other
          force majeure.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          6.4. If we breach this Agreement, you agree that your exclusive remedy
          is to recover, from us or any affiliates, resellers, distributors, and
          vendors, direct damages up to an amount equal to your Services fee for
          one month (or up to USD$10.00 if the Services are free). You
          can&apos;t recover any other damages or losses, including, without
          limitation, direct, consequential, lost profits, special, indirect,
          incidental, or punitive.
          <strong className="ml-1">
            Some or all of these limitations or exclusions may not apply to you
            if your state, province, or country doesn&apos;t allow the exclusion
            or limitation of incidental, consequential, or other damages.
          </strong>
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>7. DURATION OF TERMS</TermsBuilder.H2>
        <TermsBuilder.Text>
          7.1. Once in effect, this Agreement will continue in operation until
          terminated by either you or us. However, even after termination, the
          provisions of sections 3 through 15 of this Agreement will remain in
          effect in relation to any prior use of the Services by you. Company
          may terminate this Agreement at any time and for any reason. After
          termination of this Agreement, you understand and acknowledge that
          Company will have no further obligation to provide the Services or
          access thereto. Upon termination, all licenses and/or other rights
          granted to you by this Agreement, if any, will immediately cease, but
          your licenses to us shall survive, and certain of your obligations
          (including payment obligations, if any) will survive in accordance
          with the terms hereof.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>8. MODIFICATION OF TERMS</TermsBuilder.H2>
        <TermsBuilder.Text>
          8.1. Company may change the terms of this Agreement from time to time.
          You will be notified of any such changes via e-mail (if you have
          provided a valid email address) and/or by our posting notice of the
          changes on the Services (which may consist of publishing the changes
          on our website). Any such changes will become effective when notice is
          received or when posted on the Services, whichever first occurs. If
          you object to any such changes, your sole recourse will be to
          terminate this Agreement. Continued use of the Services following such
          notice of any such changes will indicate your acknowledgement of such
          changes and agreement to be bound by such changes.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>9. MODIFICATIONS TO SERVICES</TermsBuilder.H2>
        <TermsBuilder.Text>
          9.1. We reserve the right to modify or discontinue the Services at any
          time with or without notice to you, including without limitation by
          adding or subtracting features and functionality, third party content,
          etc. In the event of such modification or discontinuation of the
          Services, your sole remedy shall be to terminate this Agreement as set
          forth herein. Continued use of the Services following notice of any
          such changes will indicate your acknowledgement and acceptance of such
          changes and satisfaction with the Services as so modified.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>10. INTERNATIONAL USERS</TermsBuilder.H2>
        <TermsBuilder.Text>
          10.1. The Services are controlled, operated and administered by us
          from within the United States. We make no representation that this
          site is available for access or use at other locations outside the
          U.S. However, any access or use from outside the U.S. is still subject
          to this Agreement. Access to the Services is expressly prohibited from
          territories where this site or any portion thereof is illegal. You
          agree not to access or use any information or materials on the
          Services in violation of U.S. export laws and regulations, or in
          violation of any laws or regulations in the country from which you are
          accessing the Services. Any personal information which we may collect
          via the Services may be stored and processed in our servers located in
          the United States or in any other country in which we, or our
          affiliates, subsidiaries, or agents maintain facilities. You consent
          to any such transfer of personal information outside your country of
          residence to any such location..
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>11. THIRD PARTY CONTENT AND SERVICES</TermsBuilder.H2>
        <TermsBuilder.Text>
          11.1. Certain content (including without limitation advertisements) on
          the Services may be supplied by third parties. Company does not have
          editorial control over such content. You may enter into correspondence
          with or participate in promotions of such third parties, such as
          advertisers promoting their products, services or content on this
          site. Any such correspondence or participation, including the delivery
          of and the payment for products, services or content, are solely
          between you and each such third party. Under no circumstances shall
          Company, or its affiliates, or any of their respective officers,
          directors, employees, or agents, be liable for any loss or damage
          caused by your reliance on any content or other information obtained
          through the Services.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          11.2. The Services may integrate and/or interact with third party
          services, such as via APIs or browser extensions. For example, the
          Services may leverage APIs from third parties such as (without
          limitation) Facebook, WhatsApp, Twitter, LinkedIn, Google (e.g.,
          Google Classroom, Google Docs, Gmail, etc.), Tidio, Clicky, Firebase,
          etc., and/or we may rely on third party browser extensions, and
          Company has no affiliation, association, endorsement, or sponsorship
          by any other third party services with which it integrates or
          interacts from time to time (collectively, “Third Party Services”). As
          such, you may be subject to their respective privacy policies, terms
          of use or other applicable terms and conditions of such Third Party
          Services. By using the Services, you hereby release Company and waive
          any and all claims or claim rights that you may have against Company,
          and release and indemnify Company against any claims that any third
          party may have against you, including with respect to your use of any
          Third Party Services, including if accessed or used via our Services,
          and with respect to Third Party Terms, applicable privacy policies or
          any other rules or regulations of such third parties.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          11.3. As a convenience to you, Company may provide, in or through the
          Services, one or more links to third party web sites or services
          and/or provide email contacts respecting third parties. Company makes
          no endorsement of such third parties, nor any representation or
          warranty regarding anything that takes place between you and any such
          third parties, including, without limitation, visits to third party
          web sites, services, email correspondence with third parties, and
          business or other transactions with third parties found through the
          Services.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>12. DIGITAL MILLENNIUM COPYRIGHT ACT</TermsBuilder.H2>
        <TermsBuilder.Text>
          12.1. If you are a copyright owner or an agent thereof and believe
          that any content in our Services infringes upon your copyrights, you
          may submit a notification pursuant to the Digital Millennium Copyright
          Act (&quot;DMCA&quot;) by providing our Copyright Agent with the
          following information in writing (see 17 U.S.C 512(c)(3) for further
          detail):
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online site
            are covered by a single notification, a representative list of such
            works at that site;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled and information reasonably
            sufficient to permit the service provider to locate the material;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Information reasonably sufficient to permit the service provider to
            contact you, such as an address, telephone number, and, if
            available, an electronic mail;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
        <TermsBuilder.Text>
          12.2. Company&apos;s designated method to receive notifications of
          claimed infringement is by emailing the Copyright Agent at
          <a href="mailto:info@beepaudio.com" className="ml-1 text-blue-500">
            info@beepaudio.com
          </a>
          . You acknowledge that if you fail to comply with all of the
          requirements of this Section your DMCA notice may not be valid.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          12.3. If you believe that your content that was removed (or to which
          access was disabled) is not infringing, or that you have the
          authorization from the copyright owner, the copyright owner&apos;s
          agent, or pursuant to the law, to post and use the material in your
          content, you may send a counter-notice containing the following
          information to the Copyright Agent:
        </TermsBuilder.Text>
        <TermsBuilder.List>
          <TermsBuilder.ListItem>
            Your physical or electronic signature;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Identification of the content that has been removed or to which
            access has been disabled and the location at which the content
            appeared before it was removed or disabled;
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            A statement that you have a good faith belief that the content was
            removed or disabled as a result of mistake or a misidentification of
            the content; and
          </TermsBuilder.ListItem>
          <TermsBuilder.ListItem>
            Your name, address, telephone number, and e-mail address, a
            statement that you consent to the jurisdiction of the federal courts
            in Broward County, Florida, and a statement that you will accept
            service of process from the person who provided notification of the
            alleged infringement.
          </TermsBuilder.ListItem>
        </TermsBuilder.List>
        <TermsBuilder.Text>
          12.4. If a counter-notice is received by the Copyright Agent, Company
          may send a copy of the counter-notice to the original complaining
          party informing that person that it may replace the removed content or
          cease disabling it in 10 business days. Unless the copyright owner
          files an action seeking a court order against the content provider,
          member or user, the removed content may be replaced, or access to it
          restored, in 10 or more business days after receipt of the
          counter-notice, at Company&apos;s sole discretion.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>
          13. CLASS ACTION WAIVER AND ARBITRATION
        </TermsBuilder.H2>
        <TermsBuilder.Text extendClass="font-bold">
          THIS SECTION CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS ACTION
          WAIVER. IT AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH
          US.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Except to the extent contrary to applicable law (&quot;Excluded
          Disputes&quot;), you hereby agree that all disputes between you and us
          (whether or not such dispute involves a third party) with regard to
          your relationship with us, including without limitation disputes
          related to these Terms, your use of the Service, and/or rights of
          privacy and/or publicity, may, in our discretion, be resolved by
          binding, individual arbitration under the American Arbitration
          Association&apos;s rules for arbitration of consumer-related disputes
          and you hereby expressly waive trial by jury. As an alternative, you
          may bring your claim in your local &quot;small claims&quot; court, if
          permitted by that small claims court&apos;s rules. You may bring
          claims only on your own behalf. You agree that you shall not
          participate in any class action or class-wide arbitration for any
          claims covered by this Agreement. You also agree not to participate in
          claims brought in a private attorney general or representative
          capacity, or consolidated claims involving another person&apos;s
          account, if we are a party to the proceeding. Judgment on the award
          rendered by the arbitrator may be entered in any court having
          competent jurisdiction.
        </TermsBuilder.Text>
      </TermsBuilder.Section>

      <TermsBuilder.Section>
        <TermsBuilder.H2>14. MISCELLANEOUS.</TermsBuilder.H2>
        <TermsBuilder.Text>
          14.1. You shall comply with all laws, rules and regulations now or
          hereafter promulgated by any government authority or agency that are
          applicable to your use of the Services, or the transactions
          contemplated in this Agreement. You may not assign your rights or
          obligations hereunder, and any attempt by you to sublicense, assign or
          transfer any of the rights, duties or obligations hereunder or to
          exceed the scope of this Agreement is void. In the event that Company
          is sold to a third party, such a sale will not be deemed a transfer of
          personal information so long as that third party agrees to assume
          Company&apos;s obligations as to these Terms of Services and any
          associated Privacy Policy. This Agreement, the Services, and the
          rights and obligations of the parties with respect to the Services
          will be subject to and construed in accordance with the laws of the
          State of Delaware, excluding conflict of law principles. By accessing
          or using any Services you agree that the statutes and laws of the
          State of Delaware, without regard to conflicts of law principles
          thereof, will apply to all matters arising from or relating to the use
          thereof. You also agree and hereby submit to the exclusive legal
          jurisdiction and venues of the Courts of Broward County, Florida, with
          respect to such matters. This is the entire agreement between you and
          Company with regard to the matters described herein and govern your
          use of the Services, superseding any prior agreements between you and
          Company with respect thereto. The failure of Company to exercise or
          enforce any right or provision of this Agreement shall not constitute
          a waiver of such right or provision. If any provision of this
          Agreement is found by a court of competent jurisdiction to be invalid,
          the parties nevertheless agree that the court should endeavor to give
          effect to the parties&apos; intentions as reflected in the provision,
          and the other provisions hereof shall remain in full force and effect.
          You agree that regardless of any statute or law to the contrary, any
          claim or cause of action arising out of this Agreement or related to
          use of the Services must be filed within three (3) months after such
          claim or cause of action arose or be forever barred.
        </TermsBuilder.Text>
        <TermsBuilder.Text>
          Any content (in any form) you post or store using the Services is
          posted or stored at your own risk. We will use reasonable efforts to
          protect your content, but we cannot and do not guarantee security of
          all content and recordings. You should not use the Services to send or
          store sensitive information such as (but not limited to) your address,
          credit card, health information, and the like.
        </TermsBuilder.Text>
      </TermsBuilder.Section>
    </TermsBuilder>
  </Layout>
);
export default Terms;
