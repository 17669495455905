import { node, string } from 'prop-types';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PageLoader from './PageLoader';

const ProtectedRoute = ({ children, path }) => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);

  if (!currentUser && currentUser !== undefined) {
    return <Redirect to={{ pathname: '/log-in', state: path.slice(1) }} />;
  }

  if (loadingAuthState) {
    return <PageLoader />;
  }

  return (
    <Route exact path={path}>
      {children}
    </Route>
  );
};

ProtectedRoute.propTypes = {
  children: node.isRequired,
  path: string.isRequired
};

export default ProtectedRoute;
