import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Error from './pages/Error';
import Products from './pages/Products';
import Terms from './pages/Terms';
import Play from './pages/Play';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import Welcome from './pages/Welcome';
import ProtectedRoute from './components/ProtectedRoute';
import Record from './pages/Record';

import './styles/index.scss';
import DeleteAccount from './pages/DeleteAccount';

function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about-us">
          <AboutUs />
        </Route>
        <Route exact path="/products/:product">
          <Products />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/log-in">
          <Login />
        </Route>
        <Route exact path="/create-account">
          <CreateAccount />
        </Route>
        <ProtectedRoute exact path="/welcome">
          <Welcome />
        </ProtectedRoute>
        <ProtectedRoute exact path="/delete-account">
          <DeleteAccount />
        </ProtectedRoute>
        <Route exact path="/record/:service?">
          <Record />
        </Route>
        <Route exact path="/play/:audioId">
          <Play />
        </Route>
        <Route
          path="*"
          render={({ location: { state } }) => <Error {...state} />}
        />
      </Switch>
    </div>
  );
}

export default App;
