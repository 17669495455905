import { bool, node, string } from 'prop-types';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ConsentBar from './ConsentBar';
import ErrorAlertBar from './ErrorAlertBar';

const Layout = ({
  children,
  hideFooter,
  title,
  description,
  hideHeaderButtons,
  extendClass
}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const displayErrorMessage = process.env.REACT_APP_BEEP_DOWN_MESSAGE || '';

  return (
    <>
      <Header hideHeaderButtons={hideHeaderButtons} />
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main
        className={`flex-grow mx-auto px-4 sm:px-6 w-full pt-32 md:pt-40 text-center ${extendClass}`}
      >
        {children}
      </main>
      <ConsentBar />
      {displayErrorMessage.length > 0 && <ErrorAlertBar />}
      {!hideFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  title: string,
  description: string,
  extendClass: string,
  hideFooter: bool,
  hideHeaderButtons: bool,
  children: node.isRequired
};

Layout.defaultProps = {
  title: 'Beep',
  description: 'Audio notes for Google Docs, Gmail, and Google Classroom.',
  extendClass: '',
  hideFooter: false,
  hideHeaderButtons: false
};

export default Layout;
