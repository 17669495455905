import { Link } from 'react-router-dom';
import CallToAction from '../components/CallToAction';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading';
import ServiceClassroom from '../assets/beep-google-docs.png';
import Carousel from '../components/Carousel';

const Home = () => (
  <Layout
    title="Beep - Voice Notes for Google Classroom, Google Docs, & Gmail"
    description="Record and play voice notes and audio comments in Google Classroom, Gmail, Google Docs, Slides, Sheets, and Forms. Get it now for free!"
  >
    <section>
      <PageHeading
        title="Type Less, Say More."
        emphasizeTitle=""
        description={[
          'Leave voice notes, feedback, and comments on Google Classroom, Google Docs, and Gmail.'
        ]}
      />
      <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center mb-5">
        <Link
          className="btn text-white bg-black hover:bg-blue-700 w-full sm:w-auto mb-8 md:mb-10"
          to="/create-account"
        >
          Get It Now
        </Link>
      </div>
      <div className="flex flex-col justify-center text-center">
        <Link to="/create-account">
          <img
            src={ServiceClassroom}
            className="mx-auto"
            width="580"
            height="386"
            alt="Voice Comments on Docs and Recording Widget"
            aria-hidden="true"
          />
        </Link>
        <h4 className="my-8 text-sm text-gray-600">
          *We are not affiliated with or endorsed by Google™ in any way
        </h4>
      </div>
    </section>
    <Carousel />
    <CallToAction />
  </Layout>
);

export default Home;
